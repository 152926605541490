import React from "react";
import ReactDOM from "react-dom";

function IconFormSearch(data, side) {
	var dataForm = new FormData();
	let endPointApi;

	var token = data.tk;
	var icon = "";
	var idIcon = "";

	if (data.ty.substr(0, 2) === "dp") {
		icon = data.im;
	} else {
		switch (data.ty) {
			case "cm":
				icon = "camera.svg";
				break;
			case "gl":
				icon = "geolocation.svg";
				break;
			case "gm":
				icon = "geolocation.svg";
				break;
			case "id":
				if (side === "front") icon = "identityDocumentFront.svg";
				else icon = "identityDocumentRear.svg";
				idIcon = "idIcon";
				break;
			case "is":
				if (side === "front") icon = "identityDocumentFront.svg";
				else icon = "identityDocumentRear.svg";
				idIcon = "idIcon";
				break;
			case "sf":
				if (data.tk === "bB15a122") icon = "selfie-id.svg";
				else icon = "selfie.svg";
				break;
			case "sl":
				icon = "selfie.svg";
				break;
			default:
				icon = "";
				break;
		}
	}

	dataForm.append("token", token);
	dataForm.append("nameIcon", icon);

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch(
		"https://" + endPointApi + "/endpoints/ws-react-search-icons-questions.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			let imgElement = <img src={results} alt='moyoAI' id={idIcon} />;
			ReactDOM.render(imgElement, document.getElementById("idImage"));
		})
		.catch(function (err) {
			console.error(err);
		});
}

export default IconFormSearch;
