import React from "react";

import "../assets/css/input.css";

function CmInputNumber(data) {
	var vPlaceHolder = "Escribe tu respuesta aquí...";
	var vMxLength = 255;
	var answData = "";

	if (data.lh && data.lh !== 0) vMxLength = data.lh;

	if (data.aw != null) answData = data.aw;

	return (
		<div className='gnt-input-section'>
			<input
				id='gntInput'
				type='number'
				placeholder={vPlaceHolder}
				min={data.mn}
				max={data.mx}
				maxLength={vMxLength}
				defaultValue={answData}
			/>
		</div>
	);
}

export default CmInputNumber;
