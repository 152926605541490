import React from "react";
import Cookies from "universal-cookie";

import ApiSearchFirstQuestion from "../apis/apiSearchFirstQuestion";
import ApiSearchLastQuestion from "../apis/apiSearchLastQuestion";

import ErrorDevice from "../errors/errDevice";

const FnValidateDevice = (token, results) => {
	const setCookies = new Cookies();
	var element = "";
	let userAgent = navigator.platform,
		macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
		windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
		androidPlatforms = ["Linux armv8l", "Linux armv7l"],
		linuxPlatforms = ["Linux x86_64"],
		iPadPlatforms = ["iPad", "iPod"],
		iphonePlatforms = ["iPhone"],
		os = null;

	if (macosPlatforms.indexOf(userAgent) !== -1) {
		os = "Mac OS";
	} else if (windowsPlatforms.indexOf(userAgent) !== -1) {
		os = "Windows";
	} else if (androidPlatforms.indexOf(userAgent) !== -1) {
		os = "Android";
	} else if (linuxPlatforms.indexOf(userAgent) !== -1) {
		os = "Linux";
	} else if (iPadPlatforms.indexOf(userAgent) !== -1) {
		os = "Ipad";
	} else if (iphonePlatforms.indexOf(userAgent) !== -1) {
		os = "iphone";
	}
	
	var browserInfo = {
		appName: navigator.platform,
		appVersion: navigator.appVersion,
		userAgent: navigator.userAgent,
		language: navigator.language,
		platform: os,
		cookieEnabled: navigator.cookieEnabled
	};

	var currentDevice = "";
	var accessDevice = 0;
	if (browserInfo.platform === "Mac OS" || browserInfo.platform === "Windows") {
		currentDevice = "PC";
	} else {
		currentDevice = "Mobile";
	}

	if (results.device === "All") {
		accessDevice = 1;
	} else if (results.device === "PC" && currentDevice === "PC") {
		accessDevice = 1;
	} else if (results.device === "Mobile" && currentDevice === "Mobile") {
		accessDevice = 1;
	}

	if(accessDevice === 0){
		element = ErrorDevice(results.device);
	}else{
		if(setCookies.get("session"))
			element = ApiSearchLastQuestion(token);
		else
			element = <ApiSearchFirstQuestion token={token} />;
	}

	return element;
};

export default FnValidateDevice;
