import React from "react";
import ReactDOM from "react-dom";

import CmNavigator from "../components/cmNavigator";

import Birthday from "../elements/elBirthday";
import Camera from "../elements/elCamera";
import CountryDocs from "../elements/elCountryDocs";
import Countries from "../elements/elCountries";
import DropDown from "../elements/elDropDown";
import DateInput from "../elements/elDate";
import DocumentPhoto from "../elements/elDocumentPhoto";
import Email from "../elements/elEmail";
import Geolocation from "../elements/elGeolocation";
import GeolocationMap from "../elements/elGeolocationMap";
import MapGoogle from "../components/cmMapGoogle";
import IdentityDocumentFront from "../elements/elIdentityDocumentFront";
import IdentityDocumentMask from "../elements/elIdentityDocumentMask";
import Legal from "../elements/elLegal";
import LongText from "../elements/elLongText";
import MultipleChoice from "../elements/elMultipleChoice";
import MultipleInputs from "../elements/elMultipleInputs";
import NumberInput from "../elements/elNumber";
import Name from "../elements/elName";
import OpinionScale from "../elements/elOpinionScale";
import Phone from "../elements/elPhone";
import PictureChoice from "../elements/elPictureChoice";
import Signature from "../elements/elSignature";
import SelfieMask from "../elements/elSelfieMask";
import ShortText from "../elements/elShortText";
import Statement from "../elements/elStatement";
import ThankYou from "../elements/elThankYou";
import Welcome from "../elements/elWelcome";

const FnConsumeData = (data) => {
    var itemComponent = "";
    var clientHeight = 0;

    // Print Navigators Buttons
    var cmNavigatorBtns = "";
	if (
		data.tf === "onboarding" &&
		data.ty !== "ws" &&
		data.ty !== "tk"
	) {
		cmNavigatorBtns = <CmNavigator data={data} />;
	}
	ReactDOM.render(cmNavigatorBtns, document.getElementById("vw-navigator"));
    
    // Consume data
    if (data.ty.substr(0, 2) === "dp") {
		itemComponent = DocumentPhoto(data);
	} else {
        switch (data.ty) {
            case "br":
                itemComponent = Birthday(data);
                break;
            case "ws":
                itemComponent = Welcome(data);
                break;
            case "cm":
                itemComponent = Camera(data);
                break;
            case "cd":
                itemComponent = CountryDocs(data);
                break;
            case "co":
                itemComponent = Countries(data);
                break;
            case "dd":
                itemComponent = DropDown(data);
                break;
            case "dt":
                itemComponent = DateInput(data);
                break;
            case "em":
                itemComponent = Email(data);
                break;
            case "gl":
                itemComponent = Geolocation(data);
                break;
            case "gm":
                if (data.aw === null) itemComponent = GeolocationMap(data);
                else itemComponent = <MapGoogle data={data} />;
                break;
            case "id":
                itemComponent = <IdentityDocumentFront data={data} />;
                break;
            case "is":
                itemComponent = <IdentityDocumentMask data={data} />;
                break;
            case "lg":
                itemComponent = Legal(data);
                break;
            case "lt":
                itemComponent = LongText(data);
                break;
            case "mc":
                itemComponent = MultipleChoice(data);
                break;
            case "mi":
                itemComponent = MultipleInputs(data);
                break;
            case "nm":
                itemComponent = NumberInput(data);
                break;
            case "nt":
                itemComponent = Name(data);
                break;
            case "os":
                itemComponent = OpinionScale(data);
                break;
            case "ph":
                itemComponent = Phone(data);
                break;
            case "pc":
                itemComponent = PictureChoice(data);
                break;
            case "sg":
                itemComponent = Signature(data);
                break;
            case "sl":
                itemComponent = SelfieMask(data);
                break;
            case "st":
                itemComponent = ShortText(data);
                break;
            case "sm":
                itemComponent = Statement(data);
                break;
            case "tk":
                itemComponent = ThankYou(data);
                break;
            default:
                break;
        }
    }

    // Assigns the data-type attribute and evaluates the height of element to center question
    document.getElementById("wpElement").setAttribute("data-type", data.ty);
    setTimeout(function(){
        clientHeight = document.getElementById('wpElement').clientHeight;

        if(clientHeight > 800)
            document.getElementById("wrapper").style.alignItems = "start";
            else
                document.getElementById("wrapper").style.alignItems = "center";
    }, 2000);

    return itemComponent;
}

export default FnConsumeData;