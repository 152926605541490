import React, { Component } from "react";
import { DatePicker } from "react-rainbow-components";

import "../assets/css/input.css";

class CmInputDate extends Component {
	constructor(props) {
		super(props);
		var dataAns = "";
		if (this.props.data.aw !== null) {
			dataAns = new Date(this.props.data.aw);
			dataAns.setMinutes(dataAns.getMinutes() + dataAns.getTimezoneOffset());
		} else {
			dataAns = new Date();
		}

		this.state = {
			date: dataAns,
			maxDate: new Date()
		};
	}

	render() {
		const containerStyles = {
			maxWidth: 200
		};

		let minDate = new Date();
		minDate.setMonth(minDate.getMonth() - 960);

		return (
			<div
				className='rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto'
				style={containerStyles}
				id='gntInputDate'>
				<DatePicker
					value={this.state.date}
					onChange={(value) => {
						this.setState({ date: value });
					}}
					locale='es'
					className='gntDate'
					maxDate={this.state.maxDate}
					minDate={minDate}
				/>
			</div>
		);
	}
}

export default CmInputDate;
