import React from "react";

import "../assets/css/input.css";

function CmInputEmail(data) {
	var vPlaceHolder = "Escribe tu cuenta de correo...";
	var vMxLength = 255;
	var answData = "";

	if (data.lh && data.lh !== 0) vMxLength = data.lh;

	if (data.aw != null) answData = data.aw;

	return (
		<div className='gnt-input-section'>
			<input
				type='email'
				id='gntInput'
				placeholder={vPlaceHolder}
				maxLength={vMxLength}
				defaultValue={answData}
			/>
		</div>
	);
}

export default CmInputEmail;
