import React, { Fragment } from "react";

import CmTitle from "../components/cmTitle";
import CmImage from "../components/cmImage";
import CmDescription from "../components/cmDescription";
import CmMultipleChoice from "../components/cmMultipleChoice";

function MultipleChoice(data) {
	var vCmTitle = CmTitle(data);
	var vCmImage = CmImage(data);
	var vCmDescription = CmDescription(data);
	var vCmMultipleChoice = <CmMultipleChoice data={data} />;

	var question = (
		<Fragment>
			{vCmTitle}
			{vCmImage}
			{vCmDescription}
			{vCmMultipleChoice}
		</Fragment>
	);
	return question;
}

export default MultipleChoice;
