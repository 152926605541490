import React from "react";
import MaskedInput from 'react-text-mask';
import ApiAreaCode from "../apis/apiAreaCode";

import "../assets/css/input.css";

function CmInputPhone(data) {
    let dataForm = new FormData();
	dataForm.append("token", data.tk);
	var vPlaceHolder = data.ph;
	var inAreaCode = "";
	var inValue = "";
	if (data.aw != null){
		let awPhone = data.aw.split("-");
		inAreaCode = awPhone[0];
		inValue = awPhone[1];
	}

	var apiArea = <ApiAreaCode token={data.tk} data={data} areaCode={inAreaCode} />;

	return (
		<div className='gntPhone'>
			<div id="gntAreaCode">{apiArea}</div>
			<MaskedInput
				mask={[/[1-9]/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
				placeholder={vPlaceHolder}
				guide={false}
				maxLength={data.lx}
				type="tel"
				id="gntInput"
				value={inValue}
			/>
		</div>
	);
}

export default CmInputPhone;
