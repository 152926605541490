import React from "react";

import "../assets/css/description.css";

function CmDescription(data) {
	var showDS = "off";
	var idP = 0;
	var textDS = "";
	var alignDS = " text-justify";
	if (data.ds && data.ds !== "") {
		showDS = "on";
		alignDS = " " + data.dsa;
		textDS = data.ds.split("\n").map((i) => {
			idP++;
			return (
				<p key={idP}>
					{i}
				</p>
			);
		});
	}

	return (
		<div className={"txtDescription" + alignDS} data-show={showDS}>
			{textDS}
		</div>
	);
}

export default CmDescription;
