import Cookies from "universal-cookie";

import fnValidateTimerForm from "./fnValidateTimerForm";

function FnCreatePopup(data){
    const setCookies = new Cookies();

    var dataForm = new FormData();
    let endPointApi = process.env.REACT_APP_BACKEND_DEV;

    dataForm.append("token", data.tk);
    dataForm.append("section", "timerform");

    if (document.domain === "go.moyoai.com")
        endPointApi = process.env.REACT_APP_BACKEND_PROD;

    fetch(
        "https://" + endPointApi + "/react/endpoints/ws-text-alerts.php",
        {
            method: "POST",
            body: dataForm
        }
    )
        .then(function (response) {
            return response.json();
        })
        .then((results) => {
            var lang = document.documentElement.lang;
            var textMessage = results.map((e) => {
                var langText = [];
                switch(e["ml_section"]){
                    case "timerform":
                        langText["title"] = e["ml_title_"+lang];
                        langText["text"] = e["ml_text_"+lang];
                        break;
                    case "success":
                        langText["success"] = e["ml_title_"+lang];
                        break;
                    case "end":
                        langText["end"] = e["ml_title_"+lang];
                        break;
                    default:
                        break;
                }
                return langText;
            });

            // validar si tiene nuevo ciclo y obtener su tiempo
            fnValidateTimerForm(data.tk, setCookies.get("session"), data.tf, textMessage);
        })
        .catch(function (err) {
            console.error(err);
        });
    
    return null;
}

export default FnCreatePopup;