import React, { Fragment } from "react";

import CmTitle from "../components/cmTitle";
import CmDropDownCountries from "../components/cmDropDownCountries";
import CmDescription from "../components/cmDescription";
import CmDocumentsChoice from "../components/cmDocumentsChoice";
import CmButton from "../components/cmButton";

function CountryDocs(data) {
	var vCmTitle = CmTitle(data);
	var vCmDropDownCountries = CmDropDownCountries(data);
	var vCmDescription = CmDescription(data);
	var vCmDocumentsChoice = <CmDocumentsChoice data={data} />;
	var vCmButton = CmButton(data);

	var question = (
		<Fragment>
			{vCmTitle}
			<div className='gnt-dropdown-section' id='objSel'>
				{vCmDropDownCountries}
			</div>
			{vCmDescription}
			<div className='gnt-views-documents-choice' id='docchs'>
				{vCmDocumentsChoice}
			</div>
			{vCmButton}
			<div id='errorMessage'></div>
		</Fragment>
	);
	return question;
}

export default CountryDocs;
