import React from "react";
import ReactDOM from "react-dom";

function ImagePicChoiceSearch(token, imageUrl, imageId) {
	var dataForm = new FormData();
	let endPointApi;

	dataForm.append("token", token);
	dataForm.append("nameImage", imageUrl);

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch(
		"https://" +
			endPointApi +
			"/endpoints/ws-react-search-images-questions.php",
		{
			method: "POST",
			body: dataForm,
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			let imgElement = <img src={results} alt='moyoAI' />;
			ReactDOM.render(imgElement, document.getElementById("ci" + imageId));
		})
		.catch(function (err) {
			console.error(err);
		});
}

export default ImagePicChoiceSearch;
