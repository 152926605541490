import React from "react";
import ReactDOM from "react-dom";

import ApiSaveTimer from "../apis/apiSaveTimer";


function fnValidateTimerForm(token, session, typeForm, textMessage ) {
		const createPopup = ((results, typePopup) => {
			var btnContinue = "";

			const EndForm = () => {
				document.getElementById("mask").style.display = "none";
				document.getElementById("popup").style.display = "none";
				
				clearTimeout(popupEndTimer);
				ApiSaveTimer(token, session, "endTimer");
			}
		
			const ContinueForm = () => {
				//var nwTimeContinue = results["cycle_time"]*60000;

				document.getElementById("mask").style.display = "none";
				document.getElementById("popup").style.display = "none";

				clearTimeout(popupEndTimer);
				ApiSaveTimer(token, session, "continue");
			
				/* window.setTimeout(() => {
					createPopup(results, "end");
				}, nwTimeContinue); */
			}

			// tiempo de actividad del popup
			var popupEndTimer = window.setTimeout(() => {
				ReactDOM.render("", document.getElementById("popup"));
				document.getElementById("mask").style.display = "none";
				document.getElementById("popup").style.display = "none";
			
				ApiSaveTimer(token, session, "endTimer");
			}, 30000);

			if(results["cycle_form"] === "1" && typePopup !== "end"){
				btnContinue = <div className="popupButtonsItem">
					<button className="btnContinue" onClick={ContinueForm}>{textMessage[1]["success"]}</button>
				</div>;
			}

			if(typePopup === "end"){
				textMessage[0]["text"] = "Tiempo finalizado";
			}

			var popup = <div className="popup-item">
				<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
					<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
				</svg>
				<h2>{textMessage[0]["title"]}</h2>
				<p>{textMessage[0]["text"]}</p>
				<div className="popupButtons">
					<div className="popupButtonsItem">
						<button className="btnEnd" onClick={EndForm}>{textMessage[2]["end"]}</button>
					</div>
					{btnContinue}
				</div>
			</div>;

			document.getElementById("mask").style.display = "block";
			document.getElementById("popup").style.display = "block";
			ReactDOM.render(popup, document.getElementById("popup"));
		});

		var dataForm = new FormData();
		dataForm.append("token", token);
		dataForm.append("session", session);
		dataForm.append("typeForm", typeForm);
	
		let endPointApi = process.env.REACT_APP_BACKEND_DEV;
		if (document.domain === "go.moyoai.com")
			endPointApi = process.env.REACT_APP_BACKEND_PROD;
	
		fetch(
			"https://" + endPointApi + "/endpoints/ws-validate-time-form.php",
			{
				method: "POST",
				body: dataForm
			}
		)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			createPopup(results);
		})
		.catch(function (err) {
			console.error(err);
		});	
}

export default fnValidateTimerForm;
