import ReactDOM from "react-dom";

import FnValidateDevice from '../functions/fnValidateDevice';
import ErrorDevice from "../errors/errDevice";

function FnValidations(token){
	let dataForm = new FormData();
	dataForm.append("token", token);

	let endPointApi = process.env.REACT_APP_BACKEND_DEV;
	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch(
		"https://" + endPointApi + "/reactv2/endpoints/api.access.validate.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			var element = "";
			document.documentElement.lang = results.lang;

			switch(results.access){
				case 0:
					element = ErrorDevice(results.device);
					break;
				default:
					element = FnValidateDevice(token, results);
					break;
			}
            ReactDOM.render(element, document.getElementById("wpElement"));
		})
		.catch(function (err) {
			console.error(err);
		});

    return null;
}

export default FnValidations;