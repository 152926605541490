import React from "react";
import ReactDOM from "react-dom";

import "../assets/css/dropdown.css";

function CmDropDownCountries(data) {
	var cntElement = 0;
	var dataForm = new FormData();
	let endPointApi;
	var token = data.tk;

	dataForm.append("token", token);

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch("https://" + endPointApi + "/endpoints/ws-react-search-countries.php", {
		method: "POST",
		body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			var selectElement = "";
			var selectDefault = "NUL";
			var showDropDown = "on";
			var optionItems = results.map((optns) => {
				cntElement++;
				if (data.pa && data.pa !== "") {
					selectDefault = data.pa;
					showDropDown = "off";
				}

				if (optns["co_id"] === data.aw) selectDefault = data.aw;

				return (
					<option key={"e" + cntElement} value={optns.co_id.trim()}>
						{optns.co_name.trim()}
					</option>
				);
			});

			if(!data.pa || data.pa === ""){
				selectElement = (
					<select
						id='selectCountries'
						defaultValue={selectDefault}
						data-show={showDropDown}>
						{optionItems}
					</select>
				);
			}
			ReactDOM.render(selectElement, document.getElementById("objSel"));
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default CmDropDownCountries;
