import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import fnTakePhotos from "./fnTakePhotos";
import fnTimer from "./fnTimer";

function fnRestartServicesFQ(token) {
	const setCookies = new Cookies();
	if (setCookies.get("timer")) {
		fnTakePhotos(token, setCookies.get("session"));

		let timerElement = fnTimer(
			token,
			setCookies.get("session"),
			setCookies.get("timer"),
			"FQ",
			null
		);
		ReactDOM.render(timerElement, document.getElementById("fTimer"));
		document.getElementById("fTimer").classList.remove("tmInactive");
		document.getElementById("fTimer").classList.add("tmActive");
	}

	return null;
}

export default fnRestartServicesFQ;
