function ApiSendImage(token, session, imgBase64, tydocument, tyelement) {
	var formData = new FormData();
	let endPointApi;

	formData.append("token", token);
	formData.append("session", session);
	formData.append("tydocument", tydocument);
	formData.append("tyelement", tyelement);
	formData.append("imageRSA", imgBase64);

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch(
		"https://" + endPointApi + "/wservices/react/ws-react-upload-sign.php",
		{
			method: "POST",
			body: formData
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			return;
		});
}

export default ApiSendImage;
