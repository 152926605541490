import React from "react";

import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmIcon from "../components/cmIcon";
import CmButtonCamera from "../components/cmButtonCamera";
import CmButton from "../components/cmButton";
import RetryPhoto from "../functions/fnRetryPhoto";

import "../assets/css/photo.css";

function DocumentPhoto(data) {
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmIcon = CmIcon(data);
	var vCmButtonCamera = CmButtonCamera(data);
	var vCmButton = CmButton(data);

	var question = (
		<div className='vw-question'>
			<div className='vw-element' id={data.ty}>
				<div className='gnt-validate-section text-center' id='dp'>
					<div className='gnt-front-picture' id='fntPic'>
						{vCmTitle}
						{vCmDescription}
						{vCmIcon}
						{vCmButtonCamera}
					</div>
					<div className='gnt-front-preview' id='fntPicPrev'>
						{vCmTitle}

						<div className='gnt-front-preview-image'>
							<img src='' alt='' id='show-picture' />
						</div>

						<p>Verifica que la imagen sea clara</p>

						<div className='gnt-buttons-container text-center'>
							<div className='btnButtons'>
								<button
									className='btnInfo'
									id={data.id}
									onClick={RetryPhoto}>
									Reintentar
								</button>
							</div>

							{vCmButton}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	return question;
}

export default DocumentPhoto;
