import ApiGeneralQuestion from "../apis/apiGeneralQuestion";
import ApiScoreQuestion from "../apis/apiScoreQuestion";

function FnQuestions(data, answer) {
	switch (data.tf) {
		case "score":
			ApiScoreQuestion(data);
			break;
		default:
			ApiGeneralQuestion(data, answer);
			break;
	}

	return null;
}

export default FnQuestions;
