import Cookies from "universal-cookie";

function fnIdentityResources(data) {
	const setCookies = new Cookies();
	fnIdentityResourceFetch(data, setCookies.get("session"));
}

async function fnIdentityResourceFetch(data, session){
	var dataForm = new FormData();
	let endPointApi;

	dataForm.append("token", data.tk);
	dataForm.append("idForm", data.if);
	dataForm.append("session", session);

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	await fetch("https://" + endPointApi + "/react/endpoints/ws-identity-resources.php", {
			method: "POST",
			body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			return results;
		})
		.catch(function (err) {
			console.error(err);
		});
}

export default fnIdentityResources;
