import React from "react";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";
import validator from "validator";

import ApiSaveGeolocation from "../apis/apiSaveGeolocation";
import ApiSendImage from "../apis/apiSendImage";
import fnTakePhotos from "../functions/fnTakePhotos";

function FnAnswer(data) {
	const setCookies = new Cookies();
	var answer = null;
	var errorMessage = "";
	var AUX = [];

	if (data.ty.substr(0, 2) === "dp") {
		ApiSendImage(data.tk, setCookies.get("session"), "front", data.ty);
		answer = data.ty;
	} else {
		switch (data.ty) {
			case "br":
				if(document.getElementById("dateInput").getAttribute("refdate")){
					var dateBR = document.getElementById("dateInput").getAttribute("refdate").split("-");
					answer = dateBR[2] + "-" + dateBR[1] + "-" + dateBR[0];
				}

				if (answer === "" || answer === null)
					errorMessage = <p>Selecciona tu fecha de nacimiento</p>;
				break;
			case "cd":
				let countrySelected = "";
				let cards = document.getElementsByClassName("card-check");
				let valueID = "off";
				if(data.pa === ""){
					countrySelected = document.getElementById("selectCountries").value;
				}else{
					countrySelected = data.pa;
				}
				for (var i = 0; i < cards.length; i++) {
					if (cards[i].getAttribute("dataactive") === "on")
						valueID = cards[i].getAttribute("datatype");
				}
				if (countrySelected !== "NUL" && valueID !== "off") {
					answer = countrySelected + ", " + valueID;
				} else {
					answer = "";
					if (countrySelected === "NUL")
						errorMessage = <p>Seleccione el país de origen del documento</p>;
					else
						errorMessage = (
							<p>Seleccione el tipo de documento con el que se identificará</p>
						);
				}
				break;
			case "co":
				let countries = document.getElementById("selectCountries").value;
				answer = countries;

				if(answer === "NUL") errorMessage = <p>Seleciona una opción</p>;
				break;
			case "cm":
				fnTakePhotos(data.tk, setCookies.get("session"));
				answer = "camera Ok";
				break;
			case "dd":
				answer = document.getElementById("gntSelect").value;
				if (answer === "op0") errorMessage = <p>Selecciona una opción</p>;
				break;
			case "dt":
				answer = document.querySelector("input").value.replace(/\//g, "-");
				if (answer === "") errorMessage = <p>Selecciona una fecha</p>;
				break;
			case "em":
				answer = document.getElementById("gntInput").value;
				if (answer === "")
					errorMessage = <p>Por favor ingresa un correo electrónico</p>;
				else if (!validator.isEmail(answer))
					errorMessage = <p>Ingresa un email valido</p>;
				break;
			case "gl":
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {
						var lat = position.coords.latitude;
						var lon = position.coords.longitude;
						
						ApiSaveGeolocation(
							data,
							setCookies.get("session"),
							answer,
							lat,
							lon
						);
					});
				} else {
					alert(
						"Tu navegador no soporta Geolocalización, por favor actualizalo"
					);
				}
				answer = "geolocation Ok";
				break;
			case "gm":
				answer = "GeolocationMap";
				break;
			case "id":
				ApiSendImage(data.tk, setCookies.get("session"), "rear", "id");
				answer = "identityDocument";
				break;
			case "lg":
				let lgN = document.getElementById("nTerm").style.display;
				if (lgN === "none") answer = "Acepto";
				else answer = "No Acepto";
				break;
			case "lt":
				answer = document.getElementById("gntTextArea").value;
				if (answer === "")
					errorMessage = <p>Ingresa una respuesta</p>;
				break;
			case "mi":
				var arrayInput = [];
				var inputsValue = document.getElementsByClassName("mi-inp-vl");
				const namesValues = [].map.call(inputsValue, function (dataInput) {
					arrayInput.push(dataInput.value);
				});
				var answTotal = namesValues;

				answer = arrayInput;
				answTotal = document.getElementById("totEltns").value;

				if (parseInt(answTotal) === 0)
					errorMessage = <p>Por favor ingresa un valor</p>;
				break;
			case "mc":
				let objOther = document.getElementById("othValue");
				if (objOther.value === "") {
					errorMessage = <p>Ingresa tu "Otra Opción"</p>;
				}else{
					answer = "oth;" + objOther.value;
				}
				break;
			case "nt":
				let nt1 = document.getElementById("name1").value;
				let nt2 = document.getElementById("name2").value;
				let nt3 = document.getElementById("name3").value;
				if (nt1 === "") errorMessage = <p>* Por favor ingresa el nombre</p>;
				else if (nt2 === "" && nt3 === "")
					errorMessage = <p>* Por favor ingresa por lo menos un apellido</p>;
				else answer = nt1 + "-" + nt2 + "-" + nt3;
				break;
			case "ph":
				let areaCode = document.getElementById("flags-select-areacode").value;
				let phoneTel = document.getElementById("gntInput").value;
				let lengthTel = document.getElementById("length-phone-number").value;
				answer = areaCode + "-" + phoneTel.replace(/[(/)/-]/g, "").replace(" ", "");

				if(areaCode === "")
					errorMessage = "Selecciona un código de país";
				else if(phoneTel === "")
					errorMessage = "Ingresa el número telefónico";
				else if(phoneTel.length < lengthTel)
					errorMessage = "El número telefónico debe tener "+lengthTel+" dígitos";
				break;
			case "sf":
				ApiSendImage(data.tk, setCookies.get("session"), "front", "sf");
				answer = "Selfie";
				break;
			case "sg":
				answer = "Signature";
				break;
			case "sl":
				ApiSendImage(data.tk, setCookies.get("session"), "front", "sf");
				answer = "Selfie";
				break;
			case "sm":
				answer = "Statement";
				break;
			case "tk":
				if (data.rd && data.rd !== "") window.location = data.rd;
				answer = "Thankyou";
				break;
			case "ws":
				answer = "Welcome";
				break;
			default:
				answer = document.getElementById("gntInput").value;
				if (answer === "")
					errorMessage = <p>Por favor ingresa una respuesta</p>;
				break;
		}
	}
	AUX["answer"] = answer;
	AUX["errorMessage"] = errorMessage;

	if (errorMessage !== "")
		ReactDOM.render(errorMessage, document.getElementById("errorMessage"));
	
	return AUX;
}

export default FnAnswer;
