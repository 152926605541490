import React from "react";

import ImageFormSearch from "../apis/apiImageFormSearch";
import "../assets/css/images.css";

function CmImage(data) {
	var showIM = "off";
	var alignIM = " text-left";
	var imageObj = null;

	if (data.im) showIM = "on";
	if (data.ima) alignIM = " " + data.ima;

	if (data.im) {
		imageObj = (
			<div
				className={"imgImage" + alignIM}
				data-show={showIM}
				id='idImage'>
				{ImageFormSearch(data)}
			</div>
		);
	}

	return imageObj;
}

export default CmImage;
