import React, { Fragment } from "react";

import CmImage from "../components/cmImage";
import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmInputNumber from "../components/cmInputNumber";
import CmButton from "../components/cmButton";

function NumberInput(data) {
	var vCmImage = CmImage(data);
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmInputNumber = CmInputNumber(data);
	var vCmButton = CmButton(data);

	var question = (
		<Fragment>
			{vCmImage}
			{vCmTitle}
			{vCmDescription}
			{vCmInputNumber}
			{vCmButton}
			<div id='errorMessage'></div>
		</Fragment>
	);
	return question;
}

export default NumberInput;
