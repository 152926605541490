import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AppToken from "./app/appToken";
import "./assets/css/styles.css";

var appLoading = <Router>
	<Switch>
		<Route exact path='/:token/:id1' component={AppToken} />
		<Route exact path='/:token' component={AppToken} />
		<Route
			exact
			path='/'
			component={() => {
				window.location.href = "https://www.moyoai.com";
				return null;
			}}
		/>
	</Switch>
</Router>

ReactDOM.render(appLoading, document.getElementById("app"));
