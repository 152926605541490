//import ReactDOM from "react-dom";
import FnCreatePopup from "./fnCreatePopup";

function fnTimerForms(data) {
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", data.tk);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch(
		"https://" + endPointApi + "/react/endpoints/ws-timer-forms.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			var time = 0;
			switch(data["tf"]){
				case "identity":
					time = results[0]["fr_time_identity"]*60000;
					break;
				default:
					time = results[0]["fr_time_onboarding"]*60000;
					break;
			}

			window.popupTimer = window.setTimeout(() => {
				FnCreatePopup(data);
			}, time);
		})
		.catch(function (err) {
			console.error(err);
		});

	return null;
}

export default fnTimerForms;
