import React, { Fragment } from "react";
import ReactDOM from "react-dom";

import CmTitle from "../components/cmTitle";
import CmIcon from "../components/cmIcon";
import CmDescription from "../components/cmDescription";
import MapGoogle from "../components/cmMapGoogle";

export default function GeolocationMap(data) {
	var stAlign = " text-left";
	if (data.bxa && data.bxa !== "") stAlign = " " + data.bxa;
	const showMap = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				var lat = position.coords.latitude;
				var lon = position.coords.longitude;
				var maps = <MapGoogle data={data} latitude={lat} longitude={lon} />;

				ReactDOM.render("", document.getElementById("wpElement"));
				ReactDOM.render(maps, document.getElementById("wpElement"));
			});
		} else {
			alert("Tu navegador no soporta Geolocalización, por favor actualizalo");
		}
	}

	var question = (
		<Fragment>
			{CmIcon(data)}
			{CmTitle(data)}
			{CmDescription(data)}
			<div className={"btnButtons" + stAlign} data-show="on">
				<button
					className='btnSuccess'
					id={"bt" + data.ty}
					onClick={showMap}>
					{data.bx}
				</button>
			</div>
		</Fragment>
	);
	return question;
}
