import React from "react";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import IdentityDocumentBack from "../elements/elIdentityDocumentBack";
import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmDocumentSideFront from "../components/cmDocumentSideFront";
import CmIcon from "../components/cmIcon";
import CmButtonCamera from "../components/cmButtonCamera";

import RetryPhoto from "../functions/fnRetryPhoto";
import GeneralQuestion from "../apis/apiGeneralQuestion";
import fnSendImage from "../apis/apiSendImage";

import "../assets/css/photo.css";

class IdentityDocumentFront extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(data) {
		const setCookies = new Cookies();
		fnSendImage(data.tk, setCookies.get("session"), "front", "id");
		if (data.rr && data.rr === "1") {
			let idBack = IdentityDocumentBack(data);
			ReactDOM.render(idBack, document.getElementById("wrapper"));
		} else {
			let newQuestion = GeneralQuestion(
				data,
				setCookies.get("session"),
				"identityDocument"
			);
			ReactDOM.render(newQuestion, document.getElementById("wrapper"));
		}
	}

	render() {
		console.log(this.props.data);
		var vCmTitle = CmTitle(this.props.data);
		var vCmDescription = CmDescription(this.props.data);
		var vCmDocumentSideFront = CmDocumentSideFront(this.props.data);
		var descrText = "";
		var buttonRText = "";
		var buttonCText = "";
		var langID = document.getElementById("app");
		switch (langID.getAttribute("lang")) {
			case "en":
				descrText = "Check that the image is clear";
				buttonRText = "Retry";
				buttonCText = "Continue";
				break;
			default:
				descrText = "Verifica que la imagen sea clara";
				buttonRText = "Reintentar";
				buttonCText = "Continuar";
				break;
		}

		var question = (
			<div className='gnt-validate-section text-center' id='idFront' data-side='front'>
				<div className='gnt-front-picture' id='fntPic'>
					{vCmTitle}
					{vCmDocumentSideFront}
					{CmIcon(this.props.data, "front")}
					{vCmDescription}
					{CmButtonCamera(this.props.data)}
				</div>
				<div className='gnt-front-preview' id='fntPicPrev'>
					{vCmTitle}
					{vCmDocumentSideFront}

					<div className='gnt-front-preview-image'>
						<img src='' alt='' id='show-picture' />
					</div>

					<p>{descrText}</p>

					<div className='gnt-buttons-container text-center'>
						<div className='btnButtons'>
							<button
								className='btnInfo'
								id={this.props.data.id}
								onClick={RetryPhoto}>
								{buttonRText}
							</button>
						</div>

						<div className='btnButtons'>
							<button
								className='btnSuccess'
								id={this.props.data.id}
								onClick={(e) => this.handleClick(this.props.data)}>
								{buttonCText}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
		return question;
	}
}

export default IdentityDocumentFront;
