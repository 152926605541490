import React, { Fragment } from "react";
import CmButton from "../components/cmButton";
import CmDescription from "../components/cmDescription";
import CmDatePicker from "../components/cmDatepicker";
import CmTitle from "../components/cmTitle";

function Birthday(data) {
	var question = (
		<Fragment>
			{CmTitle(data)}
			{CmDescription(data)}
			{<CmDatePicker type="birthday" data={data} />}
			{CmButton(data)}
			<div id='errorMessage'></div>
		</Fragment>
	);
	return question;
}

export default Birthday;
