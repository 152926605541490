import React, { Fragment } from "react";

import CmTitle from "../components/cmTitle";
import CmIcon from "../components/cmIcon";
import CmDescription from "../components/cmDescription";
import CmButton from "../components/cmButton";

function Camera(data) {
	var vCmTitle = CmTitle(data);
	var vCmIcon = CmIcon(data);
	var vCmDescription = CmDescription(data);
	var vCmButton = CmButton(data);

	var question = (
		<Fragment>
			{vCmIcon}
			{vCmTitle}
			{vCmDescription}
			{vCmButton}
		</Fragment>
	);
	return question;
}

export default Camera;
