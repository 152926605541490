import React from "react";

const IcoIdImage = (icoType) => {
	var ico = null;

	switch (icoType) {
		case "idcard":
			ico = (
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 410.2 410.2'>
					<g>
						<g>
							<g>
								<path
									fill='#80bb1a'
									d='M126.02,209.9H95.1c-26.703,0.066-48.334,21.697-48.4,48.4v15.5c0,2.927,2.373,5.3,5.3,5.3h117.1c0.02,0,0.04,0,0.06,0
				c2.927-0.022,5.282-2.413,5.26-5.34V258.3C174.354,231.597,152.723,209.966,126.02,209.9z M163.82,268.46H57.3V258.3
				c0.109-20.831,16.969-37.691,37.8-37.8h30.92c20.831,0.109,37.691,16.969,37.8,37.8V268.46z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,257.38h-142c-2.671,0.164-4.8,2.294-4.964,4.964c-0.18,2.922,2.043,5.436,4.964,5.616h142
				c2.671-0.164,4.8-2.294,4.964-4.964C363.404,260.074,361.182,257.56,358.26,257.38z'
								/>
								<path
									fill='#80bb1a'
									d='M135.48,192.38l0.32-0.3c0.004-0.004,0.008-0.008,0.013-0.013c13.938-13.945,13.933-36.549-0.013-50.487
				c-6.684-6.712-15.767-10.484-25.24-10.48c-9.326-0.004-18.281,3.651-24.94,10.18l-0.32,0.3c-0.004,0.004-0.008,0.008-0.013,0.013
				c-13.938,13.945-13.933,36.549,0.013,50.487C99.115,205.923,121.501,206.057,135.48,192.38z M92.84,149.1l0.26-0.26
				c9.858-9.504,25.504-9.389,35.22,0.26l0.06-0.02c9.809,9.816,9.809,25.724,0,35.54l-0.26,0.26
				c-9.86,9.549-25.551,9.442-35.28-0.24C83.031,174.824,83.031,158.916,92.84,149.1z'
								/>
								<path
									fill='#80bb1a'
									d='M374.9,57.38H35.3C15.827,57.435,0.055,73.207,0,92.68v224.84c0.055,19.473,15.827,35.245,35.3,35.3h339.6
				c19.473-0.055,35.245-15.827,35.3-35.3V92.68C410.145,73.207,394.373,57.435,374.9,57.38z M399.62,317.52
				c-0.044,13.634-11.086,24.676-24.72,24.72H35.3c-13.634-0.044-24.676-11.086-24.72-24.72V92.68
				c0.044-13.634,11.086-24.676,24.72-24.72h339.6c13.634,0.044,24.676,11.086,24.72,24.72V317.52z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,142.26h-142c-2.671,0.164-4.8,2.294-4.965,4.965c-0.18,2.922,2.043,5.436,4.965,5.615h142
				c2.671-0.164,4.8-2.294,4.965-4.965C363.404,144.954,361.182,142.44,358.26,142.26z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,199.82h-142c-2.671,0.164-4.8,2.294-4.965,4.965c-0.18,2.922,2.043,5.436,4.965,5.615h142
				c2.671-0.164,4.8-2.294,4.965-4.965C363.404,202.514,361.182,200,358.26,199.82z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,171.04h-142c-2.671,0.164-4.8,2.294-4.965,4.965c-0.18,2.922,2.043,5.436,4.965,5.615h142
				c2.671-0.164,4.8-2.294,4.965-4.965C363.404,173.734,361.182,171.22,358.26,171.04z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,228.6h-142c-2.671,0.164-4.8,2.294-4.964,4.964c-0.18,2.922,2.043,5.436,4.964,5.616h142
				c2.671-0.164,4.8-2.294,4.964-4.964C363.404,231.294,361.182,228.78,358.26,228.6z'
								/>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case "passport":
			ico = (
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 371.424 371.424'>
					<g>
						<g>
							<path
								d='M243.204,68.903H98.351c-9.295,0-16.855,7.561-16.855,16.854v18.363c0,9.294,7.561,16.855,16.855,16.855h144.853
			c9.293,0,16.854-7.562,16.854-16.855V85.757C260.058,76.463,252.497,68.903,243.204,68.903z M246.058,104.12
			c0,1.574-1.281,2.855-2.854,2.855H98.351c-1.574,0-2.855-1.281-2.855-2.855V85.757c0-1.574,1.281-2.854,2.855-2.854h144.853
			c1.572,0,2.854,1.28,2.854,2.854V104.12z'
							/>
							<path
								d='M170.777,142.894c-46.184,0-83.756,37.571-83.756,83.755c0,46.182,37.572,83.754,83.756,83.754
			c46.183,0,83.756-37.572,83.756-83.754C254.533,180.465,216.96,142.894,170.777,142.894z M140.755,163.695
			c-3.399,5.117-7.115,11.415-10.496,18.688h-13.352C123.365,174.539,131.499,168.127,140.755,163.695z M101.372,219.649
			c0.828-8.262,3.1-16.104,6.564-23.266h16.783c-0.115,0.352-0.234,0.698-0.348,1.053c-2.115,6.646-3.834,14.123-4.641,22.213
			 M107.937,256.914c-3.465-7.164-5.736-15.005-6.564-23.266h17.951c0.313,7.438,1.5,15.236,3.889,23.266H107.937z M116.908,270.914
			h11.649c2.758,5.882,6.227,11.842,10.51,17.832C130.511,284.361,122.966,278.275,116.908,270.914z M163.777,296.051
			c-0.182-0.018-0.361-0.043-0.543-0.063c-0.274-0.461-0.598-0.901-0.986-1.304c-7.416-7.678-13.412-15.608-17.996-23.771h19.525
			V296.051z M163.777,256.914h-25.914c-2.688-7.579-4.221-15.339-4.6-23.266h30.514V256.914z M163.777,219.649h-30
			c0.686-5.762,1.941-11.603,3.795-17.52c0.617-1.966,1.287-3.879,1.99-5.746h24.215V219.649z M163.777,182.383h-17.861
			c7.748-14.544,16.215-23.644,16.383-23.822c0.367-0.389,0.676-0.811,0.938-1.254c0.182-0.02,0.359-0.044,0.541-0.062V182.383z
			 M224.646,182.383h-13.352c-3.381-7.274-7.098-13.571-10.498-18.688C210.054,168.126,218.189,174.539,224.646,182.383z
			 M177.777,157.246c0.18,0.018,0.359,0.042,0.539,0.062c0.264,0.445,0.574,0.869,0.945,1.26c0.117,0.124,8.609,9.18,16.391,23.816
			h-17.875V157.246z M177.777,196.383h24.217c0.648,1.724,1.27,3.488,1.846,5.298c1.932,6.071,3.238,12.061,3.941,17.968h-30.004
			V196.383z M177.777,233.649h30.514c-0.379,7.927-1.912,15.686-4.6,23.266h-25.914V233.649z M179.304,294.685
			c-0.389,0.402-0.713,0.843-0.984,1.304c-0.182,0.02-0.361,0.045-0.543,0.063v-25.137H197.3
			C192.716,279.076,186.722,287.006,179.304,294.685z M212.997,270.914h11.648c-6.061,7.36-13.605,13.446-22.16,17.833
			 M233.617,256.914h-15.275c2.387-8.029,3.576-15.828,3.889-23.266h17.951C239.353,241.91,237.081,249.75,233.617,256.914z
			 M240.181,219.649H221.82c-0.807-8.09-2.525-15.566-4.639-22.213c-0.113-0.354-0.234-0.701-0.35-1.053h16.785
			C237.081,203.545,239.353,211.387,240.181,219.649z'
							/>
							<g>
								<path d='M307.742,39.173c0.004,0.225,0.006,0.473,0.008,0.713C307.747,39.646,307.745,39.398,307.742,39.173z' />
								<path d='M307.755,40.424c0.004,0.273,0.006,0.568,0.01,0.858C307.761,40.992,307.759,40.697,307.755,40.424z' />
								<path d='M307.779,42.844c-0.004-0.35-0.006-0.701-0.01-1.032C307.773,42.143,307.775,42.494,307.779,42.844L307.779,42.844z' />
								<path d='M307.827,50.612c0.002,0.497,0.004,1.006,0.008,1.522C307.831,51.618,307.829,51.108,307.827,50.612z' />
								<path d='M307.818,48.586c0.002,0.457,0.004,0.929,0.006,1.405C307.822,49.515,307.82,49.043,307.818,48.586z' />
								<path d='M307.806,46.739c0.002,0.443,0.006,0.903,0.008,1.368C307.812,47.642,307.808,47.182,307.806,46.739z' />
								<path d='M307.794,44.926c0.002,0.398,0.006,0.815,0.008,1.233C307.8,45.74,307.796,45.324,307.794,44.926z' />
								<path d='M307.872,62.818c0,0.275,0.002,0.553,0.002,0.831C307.874,63.37,307.872,63.094,307.872,62.818z' />
								<path d='M307.847,55.064c0.002,0.455,0.004,0.919,0.004,1.387C307.851,55.983,307.849,55.519,307.847,55.064z' />
								<path d='M307.865,59.999c0,0.417,0.002,0.839,0.004,1.265C307.867,60.838,307.865,60.417,307.865,59.999z' />
								<path d='M307.837,52.727c0.002,0.487,0.004,0.986,0.006,1.491C307.841,53.712,307.839,53.214,307.837,52.727z' />
								<path d='M307.783,43.28c0.002,0.43,0.006,0.879,0.01,1.337C307.788,44.16,307.785,43.709,307.783,43.28z' />
								<path d='M307.779,42.985c0-0.048,0-0.094,0-0.142v0C307.779,42.892,307.779,42.937,307.779,42.985z' />
								<path d='M307.892,71.682c0,0.096,0,0.19,0.002,0.287C307.892,71.872,307.892,71.778,307.892,71.682z' />
								<path d='M307.878,65.49c0.002,0.369,0.002,0.737,0.004,1.111C307.88,66.228,307.88,65.858,307.878,65.49z' />
								<path d='M307.886,68.153c0,0.39,0,0.78,0.002,1.175C307.886,68.932,307.886,68.542,307.886,68.153z' />
								<path d='M307.855,57.456c0.002,0.432,0.004,0.87,0.006,1.312C307.859,58.326,307.857,57.888,307.855,57.456z' />
								<path
									d='M321.726,37.856c-0.199-13.803-4.545-24.118-12.916-30.657c-12.803-10-30.246-6.853-33.004-6.274L65.577,31.452
				c-8.934,0.409-16.082,7.783-16.082,16.815V354.57c0,9.294,7.563,16.855,16.857,16.855h208.85c9.295,0,16.855-7.561,16.855-16.855
				v-3.802h12.055c0.016,0,0.031,0,0.047,0c1.361,0,6.836-0.306,11.52-4.916c4.023-3.958,6.063-9.678,6.063-17
				c0-11.91,0.029-34.774,0.063-62.72C321.906,184.413,322.058,60.919,321.726,37.856z M278.058,354.57
				c0,1.574-1.281,2.855-2.855,2.855H66.353c-1.576,0-2.857-1.28-2.857-2.855V48.267c0-1.574,1.281-2.854,2.857-2.854h208.85
				c1.574,0,2.855,1.281,2.855,2.854V354.57z M307.742,328.852c0,4.829-1.348,7.71-3.701,7.916h-11.982V48.267
				c0-9.294-7.56-16.855-16.855-16.855h-36.18h-75.752L278.06,14.744c0.18-0.026,0.387-0.066,0.563-0.106
				c0.133-0.03,13.316-2.908,21.621,3.634c4.832,3.807,7.35,10.463,7.484,19.785c0.004,0.349,0.01,0.722,0.014,1.116
				c0.004,0.225,0.006,0.473,0.008,0.713c0.002,0.18,0.004,0.35,0.006,0.538c0.004,0.273,0.006,0.568,0.01,0.858
				c0.002,0.178,0.004,0.347,0.004,0.53c0.004,0.331,0.006,0.682,0.01,1.032c0,0.047,0,0.093,0,0.142
				c0.002,0.1,0.002,0.194,0.004,0.294c0.002,0.429,0.006,0.88,0.01,1.337c0,0.104,0.002,0.203,0.002,0.309
				c0.002,0.398,0.006,0.814,0.008,1.233c0.002,0.193,0.002,0.382,0.004,0.58c0.002,0.443,0.006,0.903,0.008,1.368
				c0.002,0.16,0.002,0.316,0.004,0.479c0.002,0.457,0.004,0.929,0.006,1.405c0.002,0.206,0.002,0.41,0.004,0.62
				c0.002,0.496,0.004,1.006,0.008,1.522c0,0.197,0,0.393,0.002,0.592c0.002,0.488,0.004,0.986,0.006,1.491
				c0.002,0.28,0.002,0.561,0.004,0.847c0.002,0.455,0.004,0.919,0.004,1.387c0.002,0.333,0.004,0.667,0.004,1.006
				c0.002,0.432,0.004,0.869,0.006,1.312c0,0.406,0.002,0.816,0.004,1.231c0,0.417,0.002,0.839,0.004,1.265
				c0,0.513,0.002,1.029,0.004,1.554c0,0.276,0.002,0.552,0.002,0.831c0.002,0.605,0.004,1.22,0.004,1.84
				c0.002,0.368,0.002,0.738,0.004,1.111c0,0.511,0.002,1.03,0.004,1.552c0,0.39,0,0.779,0.002,1.175
				c0.002,0.775,0.004,1.559,0.004,2.354c0,0.096,0,0.19,0.002,0.287c0.09,48.239-0.014,132.923-0.09,194.146
				C307.771,294.067,307.742,316.938,307.742,328.852z'
								/>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case "iddrive":
			ico = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 512 512'>
					<g>
						<g>
							<g>
								<path
									d='M196.437,218.56c-0.917,11.499-6.827,23.424-15.403,31.125c-2.923,2.624-4.181,6.677-3.221,10.517l4.352,17.429
				c0.96,3.819,3.925,6.805,7.765,7.765l38.208,9.536c5.269,1.301,10.176,3.84,14.592,7.552c4.523,3.776,11.243,3.2,15.04-1.344
				c3.755-4.523,3.157-11.243-1.344-15.04c-6.912-5.781-14.677-9.771-23.104-11.904l-32.021-7.979l-1.344-5.376
				c8.299-9.173,14.208-20.885,16.64-33.045c5.696-2.944,9.856-8.555,10.688-15.275l2.304-18.517
				c0.704-5.611-1.024-11.307-4.757-15.552c-1.152-1.323-2.475-2.475-3.904-3.435l0.555-11.328l1.941-1.963
				c5.504-5.824,12.949-18.325,1.173-36.309c-5.589-8.533-17.088-18.752-40.192-18.752c-6.805,0-22.144,0-37.035,9.365
				c-43.904,1.536-49.067,25.195-49.067,43.051c0,3.52,0.64,10.112,1.237,15.616c-1.579,1.003-3.008,2.24-4.288,3.669
				c-3.797,4.288-5.547,10.005-4.821,15.637l2.304,18.56c0.875,6.955,5.291,12.715,11.797,15.552
				c2.389,11.648,7.979,22.891,15.765,31.851l-1.557,6.272l-32.021,7.979c-25.92,6.485-44.053,29.696-44.053,56.448
				c0,5.888,4.779,10.667,10.667,10.667h150.549c5.888,0,10.667-4.779,10.667-10.667S209.771,320,203.883,320L65.6,319.979
				c3.648-12.181,13.483-21.867,26.325-25.067l38.229-9.536c3.819-0.96,6.805-3.925,7.765-7.765l4.544-18.197
				c0.939-3.733-0.235-7.701-3.072-10.347c-8.299-7.829-13.781-19.243-14.699-30.549c-0.448-5.547-5.184-8.597-10.752-8.597
				l-2.752-17.003c3.072,0,5.973-1.323,8-3.605c2.027-2.325,2.965-5.355,2.581-8.405c-0.811-6.443-2.112-18.069-2.112-21.845
				c0-11.029,0-21.461,31.189-21.781c2.176-0.021,4.309-0.704,6.101-2.005C167.104,128,178.795,128,184.405,128
				c16.384,0,20.843,6.827,22.4,9.109c4.331,6.656,2.56,8.533,1.365,9.813l-4.629,4.629c-1.877,1.877-2.987,4.373-3.115,7.019
				l-1.152,23.232c-0.128,2.901,0.213,4.907,2.219,7.04c1.984,2.112,4.053,2.517,6.955,2.56l-1.387,17.323
				C201.493,208.725,196.885,212.992,196.437,218.56z'
								/>
								<path
									d='M309.333,170.667h64c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667h-64
				c-5.888,0-10.667,4.779-10.667,10.667S303.445,170.667,309.333,170.667z'
								/>
								<path
									d='M458.667,0H53.333C23.915,0,0,23.915,0,53.333v277.333C0,360.085,23.915,384,53.333,384h157.824
				c5.909,0,10.667-4.779,10.667-10.667c0-5.888-4.779-10.667-10.667-10.667H53.333c-17.643,0-32-14.357-32-32V85.333h469.333
				v209.28c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667V53.333C512,23.915,488.085,0,458.667,0z
				 M490.667,64H21.333V53.333c0-17.643,14.357-32,32-32h405.333c17.643,0,32,14.357,32,32V64z'
								/>
								<path
									d='M458.667,170.667c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667H416
				c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667H458.667z'
								/>
								<path
									d='M512,341.333c0-8.917-6.635-15.147-16.128-15.147c-5.355,0-14.528,2.752-22.421,7.296
				c-10.432-22.016-27.499-41.536-39.723-47.616c-8.085-4.032-35.627-8.533-60.395-8.533c-24.768,0-52.309,4.501-60.416,8.533
				c-12.181,6.08-29.248,25.6-39.701,47.616c-7.915-4.544-17.088-7.296-22.443-7.296c-4.053,0-7.531,1.045-10.325,3.157
				c-3.712,2.795-5.781,7.061-5.781,11.989c0,16.64,12.907,28.181,14.4,29.44c1.152,0.981,2.475,1.6,3.84,2.027
				c-5.099,14.144-7.573,29.845-7.573,43.2c0,15.936,1.472,43.115,10.667,57.003v17.664c0,11.968,9.365,21.333,21.333,21.333h21.333
				c11.968,0,21.333-9.365,21.333-21.333v-7.232c12.885,3.221,32.981,7.232,53.333,7.232c20.352,0,40.448-4.011,53.333-7.232v7.232
				c0,11.968,9.365,21.333,21.333,21.333h21.333c11.968,0,21.333-9.365,21.333-21.333v-17.664
				c9.195-13.888,10.667-41.067,10.667-57.003c0-13.355-2.475-29.056-7.573-43.2c1.365-0.405,2.688-1.024,3.84-2.027
				C499.093,369.515,512,357.973,512,341.333z M322.411,304.981c3.989-1.835,26.752-6.315,50.923-6.315s46.933,4.48,50.859,6.272
				c8.085,4.053,26.859,25.323,34.325,48.512c-20.053,2.816-67.456,9.216-85.184,9.216c-17.728,0-65.152-6.4-85.205-9.216
				C295.595,330.261,314.368,308.992,322.411,304.981z M262.165,353.813c-0.683-0.683-1.728-1.792-2.965-3.605
				c1.408,0.661,2.752,1.408,4.011,2.155C262.869,352.853,262.507,353.301,262.165,353.813z M298.667,490.667h-21.333V480h21.333
				V490.667z M469.333,490.667H448V480h21.333V490.667z M474.24,458.667h-36.907c-1.131,0-2.261,0.192-3.371,0.533
				c-0.299,0.107-30.848,10.133-60.629,10.133c-29.781,0-60.331-10.027-60.629-10.112c-0.363-0.107-0.725-0.021-1.088-0.085
				c-0.747-0.171-1.472-0.469-2.283-0.469h-36.949c-2.795-6.379-5.739-21.077-5.739-42.645c0-3.392,0.256-6.997,0.64-10.645
				c14.336,0.405,31.381,5.248,31.381,10.624c0,5.888,4.779,10.667,10.667,10.667S320,421.888,320,416
				c0-26.069-35.2-30.869-48.533-31.765c1.195-3.904,2.624-7.573,4.245-11.029c0.021,0,0.043,0.021,0.064,0.021
				c2.987,0.448,73.408,10.773,97.557,10.773c24.149,0,94.571-10.325,97.557-10.773c0.021,0,0.021-0.021,0.043-0.021
				c1.621,3.456,3.051,7.125,4.245,11.029c-13.333,0.896-48.512,5.717-48.512,31.765c0,5.888,4.779,10.667,10.667,10.667
				c5.888,0,10.667-4.779,10.667-10.667c0-5.483,16.939-10.176,31.36-10.624c0.384,3.648,0.64,7.253,0.64,10.624
				C480,437.589,477.035,452.288,474.24,458.667z M484.48,353.792c-0.341-0.491-0.683-0.96-1.045-1.429
				c1.259-0.747,2.624-1.515,4.011-2.176C486.4,351.765,485.333,352.939,484.48,353.792z'
								/>
								<path
									d='M309.333,234.667h149.333c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667H309.333
				c-5.888,0-10.667,4.779-10.667,10.667S303.445,234.667,309.333,234.667z'
								/>
								<path
									d='M394.667,405.333H352c-5.888,0-10.667,4.779-10.667,10.667c0,5.888,4.779,10.667,10.667,10.667h42.667
				c5.888,0,10.667-4.779,10.667-10.667C405.333,410.112,400.555,405.333,394.667,405.333z'
								/>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case "idfm":
			ico = (
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 410.2 410.2'>
					<g>
						<g>
							<g>
								<path
									fill='#80bb1a'
									d='M126.02,209.9H95.1c-26.703,0.066-48.334,21.697-48.4,48.4v15.5c0,2.927,2.373,5.3,5.3,5.3h117.1c0.02,0,0.04,0,0.06,0
				c2.927-0.022,5.282-2.413,5.26-5.34V258.3C174.354,231.597,152.723,209.966,126.02,209.9z M163.82,268.46H57.3V258.3
				c0.109-20.831,16.969-37.691,37.8-37.8h30.92c20.831,0.109,37.691,16.969,37.8,37.8V268.46z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,257.38h-142c-2.671,0.164-4.8,2.294-4.964,4.964c-0.18,2.922,2.043,5.436,4.964,5.616h142
				c2.671-0.164,4.8-2.294,4.964-4.964C363.404,260.074,361.182,257.56,358.26,257.38z'
								/>
								<path
									fill='#80bb1a'
									d='M135.48,192.38l0.32-0.3c0.004-0.004,0.008-0.008,0.013-0.013c13.938-13.945,13.933-36.549-0.013-50.487
				c-6.684-6.712-15.767-10.484-25.24-10.48c-9.326-0.004-18.281,3.651-24.94,10.18l-0.32,0.3c-0.004,0.004-0.008,0.008-0.013,0.013
				c-13.938,13.945-13.933,36.549,0.013,50.487C99.115,205.923,121.501,206.057,135.48,192.38z M92.84,149.1l0.26-0.26
				c9.858-9.504,25.504-9.389,35.22,0.26l0.06-0.02c9.809,9.816,9.809,25.724,0,35.54l-0.26,0.26
				c-9.86,9.549-25.551,9.442-35.28-0.24C83.031,174.824,83.031,158.916,92.84,149.1z'
								/>
								<path
									fill='#80bb1a'
									d='M374.9,57.38H35.3C15.827,57.435,0.055,73.207,0,92.68v224.84c0.055,19.473,15.827,35.245,35.3,35.3h339.6
				c19.473-0.055,35.245-15.827,35.3-35.3V92.68C410.145,73.207,394.373,57.435,374.9,57.38z M399.62,317.52
				c-0.044,13.634-11.086,24.676-24.72,24.72H35.3c-13.634-0.044-24.676-11.086-24.72-24.72V92.68
				c0.044-13.634,11.086-24.676,24.72-24.72h339.6c13.634,0.044,24.676,11.086,24.72,24.72V317.52z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,142.26h-142c-2.671,0.164-4.8,2.294-4.965,4.965c-0.18,2.922,2.043,5.436,4.965,5.615h142
				c2.671-0.164,4.8-2.294,4.965-4.965C363.404,144.954,361.182,142.44,358.26,142.26z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,199.82h-142c-2.671,0.164-4.8,2.294-4.965,4.965c-0.18,2.922,2.043,5.436,4.965,5.615h142
				c2.671-0.164,4.8-2.294,4.965-4.965C363.404,202.514,361.182,200,358.26,199.82z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,171.04h-142c-2.671,0.164-4.8,2.294-4.965,4.965c-0.18,2.922,2.043,5.436,4.965,5.615h142
				c2.671-0.164,4.8-2.294,4.965-4.965C363.404,173.734,361.182,171.22,358.26,171.04z'
								/>
								<path
									fill='#80bb1a'
									d='M358.26,228.6h-142c-2.671,0.164-4.8,2.294-4.964,4.964c-0.18,2.922,2.043,5.436,4.964,5.616h142
				c2.671-0.164,4.8-2.294,4.964-4.964C363.404,231.294,361.182,228.78,358.26,228.6z'
								/>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case "cross":
			ico = (
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
					<path d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z' />
				</svg>
			);
			break;
		case "cameraChange":
			ico = (
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
					<path d='M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM384 256c0 8.8-7.2 16-16 16H291.3c-6.2 0-11.3-5.1-11.3-11.3c0-3 1.2-5.9 3.3-8L307 229c-13.6-13.4-31.9-21-51-21c-19.2 0-37.7 7.6-51.3 21.3L185 249c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l19.7-19.7C193.4 172.7 224 160 256 160c31.8 0 62.4 12.6 85 35l23.7-23.7c2.1-2.1 5-3.3 8-3.3c6.2 0 11.3 5.1 11.3 11.3V256zM128 320c0-8.8 7.2-16 16-16h76.7c6.2 0 11.3 5.1 11.3 11.3c0 3-1.2 5.9-3.3 8L205 347c13.6 13.4 31.9 21 51 21c19.2 0 37.7-7.6 51.3-21.3L327 327c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-19.7 19.7C318.6 403.3 288 416 256 416c-31.8 0-62.4-12.6-85-35l-23.7 23.7c-2.1 2.1-5 3.3-8 3.3c-6.2 0-11.3-5.1-11.3-11.3V320z' />
				</svg>
			);
			break;
		default:
			ico = null;
			break;
	}

	return ico;
};

export default IcoIdImage;
