import React, { Fragment } from "react";
import ReactDOM from "react-dom";

import PropTypes from "prop-types";
import Cookies from "universal-cookie";

import CmTitle from "../components/cmTitle";
import CmDocumentSideFront from "../components/cmDocumentSideFront";
import CmDocumentSideBack from "../components/cmDocumentSideBack";
import GeneralQuestion from "../apis/apiGeneralQuestion";
import SelfieCamMask from "../components/cmSelfieCamMask";
import IDFrontCam from "../components/cmIDFrontCam";
//import IDBackCam from "../components/cmIDBackCam";
import IdentityDocumentBack from "../elements/elIdentityDocumentMaskBack";
import ApiSendImage from "../apis/apiSendImageMask";
import "../assets/css/photo.css";

export const ImagePreview = ({ dataUri, data, side, isFullscreen }) => {
	let classNameFullscreen = isFullscreen ? "demo-image-preview-fullscreen" : "";

	var vCmTitle = CmTitle(data);
	var vCmDocumentSide = "";
	if (side === "back") vCmDocumentSide = CmDocumentSideBack(data);
	else vCmDocumentSide = CmDocumentSideFront(data);
	var descrText = "";
	var buttonRText = "";
	var buttonCText = "";
	var langID = document.getElementById("app");
	var idPrevElement = "fntPicPrev";
	switch (langID.getAttribute("lang")) {
		case "en":
			descrText = "Check that the image is clear";
			buttonRText = "Retry";
			buttonCText = "Continue";
			break;
		default:
			descrText = "Verifica que la imagen sea clara";
			buttonRText = "Reintentar";
			buttonCText = "Continuar";
			break;
	}

	function RetryPhoto(data, typeCam) {
		var elementCam = "";
		switch(data.ty){
			case "sl":
				elementCam = <SelfieCamMask data={data} typeCam={typeCam} />;
				break;
			case "is":
				elementCam = <IDFrontCam data={data} typeCam={typeCam} />;
				break;
			default:
				break;
		}
		ReactDOM.render(elementCam, document.getElementById("wpElement"));
	}

	function handleClick(data, dataUri) {
		const setCookies = new Cookies();
		var tyDoc = "id";

		if (data.ty === "sl") tyDoc = "sf";
		if (side === "front" && data.rr && data.rr === "1") {
			var elCamera = <IdentityDocumentBack data={data} />;
			ReactDOM.render(elCamera, document.getElementById("wpElement"));
		} else {
			let newQuestion = GeneralQuestion(
				data,
				setCookies.get("session"),
				"identityDocument"
			);
			ReactDOM.render(newQuestion, document.getElementById("wpElement"));
		}

		ApiSendImage(data, dataUri, setCookies.get("session"), side, tyDoc);
	}

	if(data.ty === "is" || data.ty === "sl")
		idPrevElement = "fntPicPrevMsk";


	return (			
		<Fragment>
			<div className={"gnt-front-preview " + classNameFullscreen} id={idPrevElement}>
				{vCmTitle}
				{vCmDocumentSide}

				<div className='gnt-front-preview-image'>
					<img width="250" src={dataUri} id='show-picture' alt='' />
				</div>

				<p>{descrText}</p>

				<div className='gnt-buttons-container text-center'>
					<div className='btnButtons'>
						<button className='btnInfo' id={data.id} onClick={(e) => RetryPhoto(data, side)}>
							{buttonRText}
						</button>
					</div>

					<div className='btnButtons'>
						<button
							className='btnSuccess'
							id={data.id}
							onClick={(e) => handleClick(data, dataUri)}>
							{buttonCText}
						</button>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

ImagePreview.propTypes = {
	dataUri: PropTypes.string,
	isFullscreen: PropTypes.bool
};

export default ImagePreview;
