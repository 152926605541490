import React from "react";
import ReactDOM from "react-dom";

import GeneralQuestion from "../apis/apiGeneralQuestion";
import ApiScoreQuestion from "../apis/apiScoreQuestion";
import ImagePicChoiceSearch from "../apis/apiImagePicChoiceSearch";

import IcoCheck from "../assets/icons/icCheck";

import "../assets/css/pictureChoice.css";

class CmPictureChoice extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(id) {
		var cards = document.getElementsByClassName("card-check");
		var e = document.getElementById(id);

		for (var i = 0; i < cards.length; i++) {
			cards[i].style.display = "none";
		}
		e.style.display = "block";

		switch (this.props.data.tf) {
			case "score":
				setTimeout(() => {
					let newQuestion = ApiScoreQuestion(this.props.data, id);
					ReactDOM.render(newQuestion, document.getElementById("wpElement"));
				}, 500);
				break;
			default:
				setTimeout(() => {
					let newQuestion = GeneralQuestion(this.props.data, id);
					ReactDOM.render(newQuestion, document.getElementById("wpElement"));
				}, 500);
				break;
		}
	}

	render() {
		var cntElement = 0;
		var objectItems = Object.keys(this.props.data).map((key) => {
			if (key.substring(0, 2) === "pc") {
				cntElement++;
				return (
					<div
						className='card'
						onClick={(e) => this.handleClick(key)}
						key={"e" + cntElement}>
						<div className='card-body'>
							<h5 className='card-title text-left'>
								<span>{cntElement}</span>
								{this.props.data["pc" + cntElement]}
							</h5>
						</div>
						<div className='card-image' id={"ci" + cntElement}>
							{ImagePicChoiceSearch(
								this.props.data.tk,
								this.props.data["pim" + cntElement],
								cntElement
							)}
						</div>
						<div className='card-check' id={key}>
							<div className='card-check-mask'></div>
							{IcoCheck()}
						</div>
					</div>
				);
			} else {
				return null;
			}
		});

		return (
			<div className='gnt-choice-section' data-align='horizontal'>
				{objectItems}
			</div>
		);
	}
}

export default CmPictureChoice;
