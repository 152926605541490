import React from "react";

import CmButton from "../components/cmButton";
import FnNextQuestion from "../functions/fnNextQuestion";

import IcoCheck from "../assets/icons/icCheck";

import "../assets/css/legal.css";

class CmLegal extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = { questionItem: null };
	}
	componentDidMount(){
		if(this.props.data.aw === "Acepto")
			document.getElementById("aTerm").style.display = "block";
	}

	handleClick(key) {
		var optsCheck = document.getElementsByClassName("chck");
		for (var i = 0; i < optsCheck.length; i++) {
			optsCheck[i].style.display = "none";
		}
		if (key !== "a") {
			document.getElementById("nTerm").style.display = "block";
			document.getElementById("terms").style.display = "block";
		} else {
			document.getElementById("aTerm").style.display = "block";
			document.getElementById("terms").style.display = "none";

			setTimeout(() => {
				FnNextQuestion(this.props.data, "A");
			}, 1000);
		}
	}

	render() {
		var vCmButton = CmButton(this.props.data);
		var agreeName = "Acepto";
		var agreeLetter = "A";
		var DisagreeName = "No acepto";
		var DisagreeLetter = "N";
		var legend =
			"Para poder continuar debes aceptar las políticas de privacidad, confirmas que no deseas aceptarlas? el proceso finalizará";
		if (document.documentElement.lang === "en") {
			agreeName = "Agree";
			agreeLetter = "A";
			DisagreeName = "Disagree";
			DisagreeLetter = "D";
			legend =
				"In order to continue you must accept the privacy policies, confirm that you do not want to accept them? the process will end";
		}

		return (
			<div className='gnt-legal-section'>
				<ul className='gnt-forms-short'>
					<li onClick={(e) => this.handleClick("a")}>
						<div className='gnt-forms-short-item'>
							<div className='gnt-legal-item'>
								<span className='chid'>{agreeLetter}</span>
								<span className='chtx'>{agreeName}</span>
								<span className='chck' id='aTerm'>
									{IcoCheck()}
								</span>
							</div>
						</div>
					</li>
					<li onClick={(e) => this.handleClick("n")}>
						<div className='gnt-forms-short-item'>
							<div className='gnt-legal-item'>
								<span className='chid'>{DisagreeLetter}</span>
								<span className='chtx'>{DisagreeName}</span>
								<span className='chck' id='nTerm'>
									{IcoCheck()}
								</span>
							</div>
						</div>
					</li>
				</ul>

				<div className='gnt-legal-confirm' id='terms'>
					<p>{legend}</p>
					{vCmButton}
				</div>
			</div>
		);
	}
}

export default CmLegal;
