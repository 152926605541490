import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import ApiFirstEvaluation from "../apis/apiFirstEvaluation";
import LastEvaluation from "../apis/apiLastEvaluation";

function fnTimer(token, session, seconds, typeForm, redirect, vanishing) {
	const setCookies = new Cookies();
	var time = secondsToTime(seconds);
	var idNextQuestion = "24";

	window.timer = window.setInterval(() => {
		time = secondsToTime(seconds - 1);
		seconds = seconds - 1;
		let elementTimer = time.m + ":" + time.s;

		if (seconds === 0) {
			switch (typeForm) {
				case "NFQ":
					LastEvaluation(token, session);
					break;
				case "TK":
					if (redirect === "") redirect = "/" + token;
					window.location.href = redirect;
					break;
				default:
					if (vanishing === 1) idNextQuestion = "4";
					ApiFirstEvaluation(token, idNextQuestion, vanishing);
					break;
			}
			setCookies.remove("timer");
			clearInterval(window.timer);
		} else {
			setCookies.set("timer", seconds, { path: "/" });
		}

		var timerClock = document.getElementById("fTimer");
		timerClock.style.display = "block";

		ReactDOM.render("", document.getElementById("fTimer"));
		ReactDOM.render(elementTimer, document.getElementById("fTimer"));
	}, 1000);
	return time.m + ":" + time.s;
}

function secondsToTime(secs) {
	let hours = ("0" + Math.floor(secs / (60 * 60))).slice(-2),
		divisor_for_minutes = secs % (60 * 60),
		minutes = ("0" + Math.floor(divisor_for_minutes / 60)).slice(-2),
		divisor_for_seconds = divisor_for_minutes % 60,
		seconds = ("0" + Math.ceil(divisor_for_seconds)).slice(-2);

	let obj = {
		h: hours,
		m: minutes,
		s: seconds
	};
	return obj;
}

export default fnTimer;
