import React from "react";

import "../assets/css/title.css";

function CmTitle(data) {
	var alignTI = " text-left";
	var numberQuestion = "";
	var txNumb = "txNum_0";

	if (data.tia) alignTI = " " + data.tia;
	if (data.oq && data.oq !== 0)
		numberQuestion = <span className='numberTitle'>{data.oq}. </span>;

	if(numberQuestion !== "")
		txNumb = "txNum_1";

	return (
		<div className={"txtTitle" + alignTI}>
			<h1>
				<span className={"tlBullet " + txNumb}>{numberQuestion}</span>
				<span className='tlText'>{data.ti}</span>
			</h1>
		</div>
	);
}

export default CmTitle;
