import { Component } from "react";
import FnConsumeData from "../functions/fnConsumeData";

class ApiSearchFirstQuestion extends Component {
    constructor() {
        super();
        this.state = {element: ""};
    }

    componentDidMount(){
        var dataForm = new FormData();
        let endPointApi = process.env.REACT_APP_BACKEND_DEV;
    
        dataForm.append("token", this.props.token);
    
        if (document.domain === "go.moyoai.com")
            endPointApi = process.env.REACT_APP_BACKEND_PROD;
    
        fetch(
            "https://" + endPointApi + "/react/endpoints/ws-search-first-question.php",
            {
                method: "POST",
                body: dataForm
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then((results) => {
                var resElement = FnConsumeData(results);
                this.setState( { element: resElement } );
                setTimeout(function(){
                    var clientHeight = document.getElementById('wpElement').clientHeight;
                    if(clientHeight > 800)
                        document.getElementById("wrapper").style.alignItems = "start";
                    else
                        document.getElementById("wrapper").style.alignItems = "center";
                }, 5000);
            })
            .catch(function (err) {
                console.error(err);
            });
    }

    render(){
        return this.state.element;
    }
}

export default ApiSearchFirstQuestion;