import React from "react";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import IdentityDocumentBack from "../elements/elIdentityDocumentBack";
import GeneralQuestion from "../apis/apiGeneralQuestion";
import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmDocumentSideBack from "../components/cmDocumentSideBack";
import CmIcon from "../components/cmIcon";
import CmButtonCameraMask from "../components/cmButtonCameraMask";
import fnSendImage from "../apis/apiSendImage";

import "../assets/css/photo.css";

class IdentityDocumentMask extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(data) {
		const setCookies = new Cookies();
		fnSendImage(data.tk, setCookies.get("session"), "rear", "id");
		if (data.rr && data.rr === "1") {
			let idBack = IdentityDocumentBack(data);
			ReactDOM.render(idBack, document.getElementById("wrapper"));
		} else {
			let newQuestion = GeneralQuestion(
				data,
				setCookies.get("session"),
				"identityDocument"
			);
			ReactDOM.render(newQuestion, document.getElementById("wrapper"));
		}
	}

	render() {
		var vCmTitle = CmTitle(this.props.data);
		var vCmDescription = CmDescription(this.props.data);
		var vCmDocumentSideBack = CmDocumentSideBack(this.props.data);
		var question = (
			<div className='vw-question'>
				<div className='vw-element' id={this.props.data.ty} data-side='rear'>
					<div className='gnt-validate-section text-center' id='idRear'>
						<div className='gnt-front-picture' id='fntPic'>
							{vCmTitle}
							{vCmDocumentSideBack}
							{CmIcon(this.props.data, "rear")}
							{vCmDescription.key}
							{CmButtonCameraMask(this.props.data, "rear")}
						</div>
					</div>
				</div>
			</div>
		);
		return question;
	}
}

export default IdentityDocumentMask;