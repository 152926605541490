import React from "react";
import QRCode from "react-qr-code";

import "../assets/css/messages.css";

function ErrorDevice(devType) {
	const actualUrl = window.location.href;
	var messageDevice = null;

	switch (devType) {
		case "Private":
			messageDevice = (
				<div className='messageDevice'>
					<h2>Acceso denegado</h2>
					<p>El formulario es privado</p>
				</div>
			);
			break;
		case "PC":
			messageDevice = (
				<div className='messageDevice'>
					<h2>Dispositivo no valido</h2>
					<p>
						No puede ingresar a esta evaluación desde una Tablet o Teléfono
						móvil, favor de copiar la URL y pegarla en su navegador desde una
						PC
					</p>
				</div>
			);
			break;
		case "Mobile":
			messageDevice = (
				<div className='messageDevice'>
					<h2>Dispositivo no valido</h2>
					<p>
						No puede ingresar a esta evaluación desde una PC, favor de
						escanear el código QR desde su dispositivo móvil
					</p>
					<div className="errorQR">
						{
							<QRCode value={actualUrl} />
						}
					</div>
				</div>
			);
			break;
		case "restraint":
			messageDevice = (
				<div className='messageDevice'>
					<h2>Restricción administrativa</h2>
					<p>Has superado el número de evaluaciones permitidas, favor de contactar al administrador</p>
				</div>
			);
			break;
		default:
			messageDevice = (
				<div className='messageDevice'>
					<h2>Restricción administrativa</h2>
					<p>No estas autorizado para ingresar a esta evaluación</p>
				</div>
			);
			break;
	}

	return messageDevice;
}

export default ErrorDevice;
