import React from "react";
import ReactDOM from "react-dom";
import ImagePreview from "../components/cmImagePreview";

function TakePhotosMask(dataUri, data, side){
    var crop = "";
    var drawImageSize = "";
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let userPlatform = navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    androidPlatforms = ["Linux armv8l", "Linux armv7l"],
    linuxPlatforms = ["Linux x86_64"],
    iPadPlatforms = ["iPad", "iPod"],
    iphonePlatforms = ["iPhone"],
    os = null,
    currentDevice = "Mobile";

    if (macosPlatforms.indexOf(userPlatform) !== -1) {
        os = "Mac OS";
    } else if (windowsPlatforms.indexOf(userPlatform) !== -1) {
        os = "Windows";
    } else if (androidPlatforms.indexOf(userPlatform) !== -1) {
        os = "Android";
    } else if (linuxPlatforms.indexOf(userPlatform) !== -1) {
        os = "Linux";
    } else if (iPadPlatforms.indexOf(userPlatform) !== -1) {
        os = "Ipad";
    } else if (iphonePlatforms.indexOf(userPlatform) !== -1) {
        os = "iphone";
    }

    if (os === "Mac OS" || os === "Windows")
        currentDevice = "PC";

    if (/android/i.test(userAgent) && currentDevice === "Mobile"){
        if(data.idDocument === "passport"){
            crop = { width: 640, height: 500};
            drawImageSize = {wsize: 640, hsize: 500, xcropp: 0, ycropp: 0, wcropp: 640, hcropp:500};
        }else{
            if(data.ty === "sl"){
                crop = { width: 480, height: 640 };
                drawImageSize = {wsize: 520, hsize: 640, xcropp: 0, ycropp: 0, wcropp: 520, hcropp:640};
            }else{
                crop = { width: 480, height: 320 };
                drawImageSize = {wsize: 640, hsize: 320, xcropp: 0, ycropp: 0, wcropp: 640, hcropp:320};
            }
        }
    }else if (/iPad|iPhone|iPod/.test(userAgent) && currentDevice === "Mobile"){
        if(data.idDocument === "passport"){
            crop = { width: 640, height: 500};
            drawImageSize = {wsize: 640, hsize: 500, xcropp: 0, ycropp: 0, wcropp: 640, hcropp:500};
        }else{
            if(data.ty === "sl"){
                crop = { width: 480, height: 640 };
                drawImageSize = {wsize: 520, hsize: 640, xcropp: 0, ycropp: 0, wcropp: 520, hcropp:640};
            }else{
                crop = { width: 480, height: 350 };
                drawImageSize = {wsize: 640, hsize: 350, xcropp: 0, ycropp: 0, wcropp: 640, hcropp:350};
            }
        }
    }else{
        if(data.idDocument === "passport"){
            crop = { width: 640, height: 500};
            drawImageSize = {wsize: 640, hsize: 500, xcropp: 0, ycropp: 0, wcropp: 640, hcropp:500};
        }else{
            if(data.ty === "sl"){
                crop = { width: 640, height: 480 };
                drawImageSize = {wsize: 640, hsize: 480, xcropp: 0, ycropp: 0, wcropp: 640, hcropp:480};
            }else{
                crop = { width: 640, height: 450 };
                drawImageSize = {wsize: 640, hsize: 450, xcropp: 0, ycropp: 0, wcropp: 640, hcropp:450};
            }
        }
    }

    let cropprImg = new Image();
    cropprImg.src = dataUri;
    cropprImg.setAttribute('id', 'croppr');
    const canvasCrop = document.createElement("canvas");
    canvasCrop.width = crop.width;
    canvasCrop.height = crop.height;
    const ctx = canvasCrop.getContext('2d');
    ctx.drawImage(cropprImg, 0, 0, drawImageSize["wsize"], drawImageSize["hsize"], drawImageSize["xcropp"], drawImageSize["ycropp"], drawImageSize["wcropp"], drawImageSize["hcropp"]);
    var dataImg = canvasCrop.toDataURL();
    var imagePreview = <ImagePreview
        dataUri={dataImg}
        data={data}
        side={side}
    />;

    ReactDOM.render(imagePreview, document.getElementById("wpElement"));
}

export default TakePhotosMask;