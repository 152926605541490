import React from "react";

import ShowPictureCam from "../functions/fnShowPictureCam";

function CmButtonCamera(data) {
	var camActive = "environment";
	if (data.ty === "sf") camActive = "user";

	return (
		<div className='btnButtons cam'>
			<label className={"btnSuccess"}>
				Tomar foto
				<input
					type='file'
					accept='image/*'
					onChange={ShowPictureCam}
					capture={camActive}
					id='take-picture'
				/>
			</label>
		</div>
	);
}

export default CmButtonCamera;
