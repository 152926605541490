import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import ApiFirstEvaluation from "../apis/apiFirstEvaluation";
import LastEvaluation from "../apis/apiLastEvaluation";
import fnTimerScore from "../functions/fnTimerScore";
import FnConsumeData from "../functions/fnConsumeData";

function ApiScoreQuestion(data, idAnswer) {
	const setCookies = new Cookies();
	var dataForm = new FormData();
	var session = setCookies.get("session");
	var endPointApi = process.env.REACT_APP_BACKEND_DEV;
	var newQuestion = "";

	dataForm.append("token", data.tk);
	dataForm.append("session", session);
	dataForm.append("idQuestion", data.id);
	dataForm.append("idAnswer", idAnswer);
	dataForm.append("idForm", data.if);
	dataForm.append("ordForcedQst", data.oq);
	dataForm.append("typeForm", data.tf);
	dataForm.append("typeQuestion", data.ty);
	if (idAnswer !== "No Acepto") dataForm.append("idLogicOrder", data.lc);
		else dataForm.append("idLogicOrder", "9999");
	dataForm.append("orderQuestion", data.oq);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	var urlFetch = "https://" + endPointApi + "/react/endpoints/ws-search-forced-question.php";
	if(data.tq === "NFQ")
		urlFetch = "https://" + endPointApi + "/endpoints/ws-react-search-nonforced-question.php";

	var loaderOut = document.getElementById("loader");
	loaderOut.style.display = "block";

	fetch(
		urlFetch,
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			if(data.tq === "NFQ"){
				if (results.ty === "tk" || results.ty === null || results === "false") {
					LastEvaluation(data.tk, setCookies.get("session"));
				}
				newQuestion = FnConsumeData(results);
				ReactDOM.render("", document.getElementById("wpElement"));
				ReactDOM.render(newQuestion, document.getElementById("wpElement"));
	
				loaderOut.style.display = "none";
			}else{
				if (results !== null) {
					if (results.oq && results.oq === 1) {
						fnTimerScore(data.tk, session, "FQ");
						document.getElementById("fTimer").classList.remove("tmInactive");
						document.getElementById("fTimer").classList.add("tmActive");
					}
	
					loaderOut.style.display = "none";
					newQuestion = FnConsumeData(results);
					ReactDOM.render(newQuestion, document.getElementById("wpElement"));
				} else {
					loaderOut.style.display = "none";
					ApiFirstEvaluation(data.tk, data.oq, data.cf);
				}
			}
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default ApiScoreQuestion;
