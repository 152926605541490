import React, { Component, StrictMode } from "react";

import FnValidations from "../functions/fnValidations";
import ScreenLandscape from "../components/cmScreenLandscape";
import fnChangeColors from "../functions/fnChangeColors";

import "../assets/css/app.css";

class AppToken extends Component {
    constructor() {
        super();
        this.state = {validateForm: 0};
    }

    UNSAFE_componentWillMount(){
        var token = this.props.match.params.token;
        fnChangeColors(token);
        this.setState({ validateForm: FnValidations(token) });
    }

    render(){
        return <StrictMode>
            <div id="mask"></div>
            <div className='loader' id='loader'><div className='loaderICON'></div></div>
            <div id="popup"></div>
            <div className='vw-video'>
                <video src='' id='video' width='400' height='200'></video>
                <canvas id='canvas'></canvas>
            </div>
            <div id="wrapper">
                <div className="element" id="wpElement"></div>
            </div>
            <div className="footer">
                <div className="footerNotes">
                    <div id='vw-navigator' className='vw-navigator'></div>
					<div className='vw-timer' id='vwTimer' data-ev='false'>
						<p className='tmInactive' id='fTimer'></p>
					</div>
                </div>
                <div className="footerBrand">powered by <span>moyoAI</span></div>
            </div>

            {ScreenLandscape()}
        </StrictMode>
    }
}

export default AppToken;