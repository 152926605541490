import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import fnConsumeData from "../functions/fnConsumeData";
import fnTimer from "../functions/fnTimer";
import fnTimerScore from "../functions/fnTimerScore";

function ApiFirstEvaluation(token, idNext, vanQuestion) {
	if (vanQuestion === undefined) {
		vanQuestion = 0;
	}

	const setCookies = new Cookies();
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", token);
	dataForm.append("session", setCookies.get("session"));
	dataForm.append("idNext", idNext);
	dataForm.append("vanQuestion", vanQuestion);

	var loaderOut = document.getElementById("loader");
	loaderOut.style.display = "block";

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch("https://" + endPointApi + "/endpoints/ws-react-first-evaluation.php", {
		method: "POST",
		body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			// Eliminamos Timer de las FQ y limpiamos contador
			clearInterval(window.timer);
			ReactDOM.render("00:00", document.getElementById("fTimer"));

			if (results.ty !== "tk") { // Inicia timer para las NFQ, si no manda al Thankyou Page
				fnTimerScore(token, setCookies.get("session"), "NFQ");
			} else { // Inicia timer del Thankyou Page
				document.getElementById("fTimer").classList.remove("tmActive");
				document.getElementById("fTimer").classList.add("tmInactive");

				fnTimer(
					token,
					setCookies.get("session"),
					"10",
					"TK",
					results.rd,
					results.cf
				);
			}

			// Consume datos e imprime la nueva pregunta
			var newQuestion = fnConsumeData(results);
			ReactDOM.render("", document.getElementById("wpElement"));
			ReactDOM.render(newQuestion, document.getElementById("wpElement"));

			// Quita loader
			loaderOut.style.display = "none";
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default ApiFirstEvaluation;
