import imageCompression from "browser-image-compression";

function ShowPictureCam(side) {
	var takePicture = document.querySelector("#take-picture"),
		showPicture = document.querySelector("#show-picture");

	if (takePicture && showPicture) {
		var files = takePicture.files,
			file;
		if (files && files.length > 0) {
			file = files[0];

			var options = {
				maxSizeMB: 0.35,
				maxWidthOrHeight: 768,
				useWebWorker: false
			};

			imageCompression(file, options)
				.then(function (compressedFile) {
					let urlImage = URL.createObjectURL(compressedFile);
					showPicture.src = urlImage;
					showPicture.onload = function () {
						URL.revokeObjectURL(urlImage);
						document.getElementById("fntPic").style.display = "none";
						document.getElementById("fntPicPrev").style.display = "block";
					};
				})
				.catch(function (error) {
					console.log(error.message);
				});
		}
	}
}

export default ShowPictureCam;
