import React from "react";

import FnNextQuestion from "../functions/fnNextQuestion";
import "../assets/css/button.css";

function CmButton(data) {
	var alignBT = " text-left";
	var showBT = "off";
	var redirectUrl = null;

	if (data.bx && data.bx !== "") showBT = "on";
	if (data.bxa && data.bxa !== "") alignBT = " " + data.bxa;
	if (data.ty === "tk" && data.rd) redirectUrl = data.rd;

	const nxtQuestion = () => {
		FnNextQuestion(data);
	};

	return (
		<div className={"btnButtons" + alignBT} data-show={showBT}>
			<button
				className='btnSuccess'
				url={redirectUrl}
				id={"bt" + data.ty}
				onClick={nxtQuestion}>
				{data.bx}
			</button>
		</div>
	);
}

export default CmButton;
