function fnChangeColors(token) {
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", token);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch("https://" + endPointApi + "/endpoints/ws-react-change-colors.php", {
		method: "POST",
		body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			if (results !== "false") {
				results.forEach((color) => {
					document.documentElement.style.setProperty(
						"--" + color["cl_element"],
						color["cl_color"]
					);
				});
			}
		})
		.catch(function (err) {
			console.error(err);
		});

	return null;
}

export default fnChangeColors;
