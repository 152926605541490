function ApiSendImage(data, dataUri, session, tydocument, tyelement) {
	var imgBase64 = dataUri.replace(/^data:image.+;base64,/, "");

	var formData = new FormData();
	let endPointApi;
	let tySide = tydocument;

	if (tydocument === "back") tySide = "rear";

	formData.append("token", data.tk);
	formData.append("session", session);
	formData.append("tydocument", tySide);
	formData.append("tyelement", tyelement);
	formData.append("imageRSA", imgBase64);

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch(
		"https://" + endPointApi + "/wservices/react/ws-react-upload-photos.php",
		{
			method: "POST",
			body: formData
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			return;
		});
}

export default ApiSendImage;
