import fnSendPhotos from "../apis/apiSendPhotos.js";

function TakePhotos(token, session) {
	var image = "";
	let video = document.getElementById("video"),
		canvas = document.getElementById("canvas"),
		width = 400,
		height = 200;
	navigator.mediaDevices
		.getUserMedia({
			audio: false,
			video: true
		})
		.then((stream) => {
			video.srcObject = stream;
			video.play();
		});

	setTimeout(() => {
		canvas.getContext("2d").drawImage(video, 0, 0, width, height);
		canvas.toDataURL("image/jpg");
		fnSendPhotos(token, session, image);
	}, 1000);

	setInterval(() => {
		canvas.getContext("2d").drawImage(video, 0, 0, width, height);
		image = canvas.toDataURL("image/png").replace(/^data:image.+;base64,/, "");
		fnSendPhotos(token, session, image);
	}, 45000);
}

export default TakePhotos;
