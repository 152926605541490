import ReactDOM from "react-dom";

import fnConsumeData from "../functions/fnConsumeData";

function ApiSaveTimer(token, session, typeForm){
    // Guardar salida
    var dataForm = new FormData();
    let endPointApi = process.env.REACT_APP_BACKEND_DEV;

    dataForm.append("token", token);
    dataForm.append("session", session);
    dataForm.append("typetimer", typeForm);

    if (document.domain === "go.moyoai.com")
        endPointApi = process.env.REACT_APP_BACKEND_PROD;

    fetch(
        "https://" + endPointApi + "/react/endpoints/ws-save-timer-forms.php",
        {
            method: "POST",
            body: dataForm
        }
    )
        .then(function (response) {
            return response.json();
        })
        .then((results) => {
            if(typeForm === "endTimer"){
                // Enviar al thankyou page
                var newQuestion = fnConsumeData(results);
			    ReactDOM.render("", document.getElementById("wpElement"));
                ReactDOM.render(newQuestion, document.getElementById("wpElement"));
            }
        })
        .catch(function (err) {
            console.error(err);
        });
}

export default ApiSaveTimer;