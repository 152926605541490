function fnSaveGeolocation(data, session, answer, lat, lng) {
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", data.tk);
	dataForm.append("session", session);
	dataForm.append("idQuestion", data.id);
	dataForm.append("idForm", data.if);
	dataForm.append("answer", answer);
	dataForm.append("lat", lat);
	dataForm.append("lon", lng);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch("https://" + endPointApi + "/react/endpoints/ws-save-geolocation.php", {
		method: "POST",
		body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			return null;
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default fnSaveGeolocation;
