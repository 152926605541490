import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import fnConsumeData from "../functions/fnConsumeData";
import fnNextForm from "../functions/fnNextForm";
import fnTimer from "../functions/fnTimer";

function ApiGeneralQuestion(data, answer){
	const setCookies = new Cookies();
	var nextQuestion = "";
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", data.tk);
	dataForm.append("session", setCookies.get("session"));
	dataForm.append("idQuestion", data.id);
	dataForm.append("idLogicOrder", data.lc);
	dataForm.append("idForm", data.if);
	dataForm.append("typeForm", data.tf);
	dataForm.append("typeQuestion", data.ty);
	dataForm.append("answer", answer);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch(
		"https://" +
			endPointApi +
			"/react/endpoints/ws-search-general-question.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			if(results !== "false"){
				if(results.tq === "FQ" && setCookies.get("timer") === undefined){
					fnTimer(data.tk, setCookies.get("session"), "150", "FQ", "", 1);
				}
				nextQuestion = fnConsumeData(results);
			}else{
				nextQuestion = fnNextForm(data, setCookies.get("session"));
			}
			document.getElementById("loader").style.display = "none";
			ReactDOM.render(nextQuestion, document.getElementById("wpElement"));
		})
		.catch(function (err) {
			console.error(err);
		});
}

export default ApiGeneralQuestion;