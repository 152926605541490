import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import FnConsumeData from "../functions/fnConsumeData";

function LastEvaluation(token, session) {
	const setCookies = new Cookies();
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;
	var newQuestion = "";

	dataForm.append("token", token);
	dataForm.append("session", setCookies.get("session"));

	clearInterval(window.timer);
	document.getElementById("fTimer").style.display = "none";
	ReactDOM.render("", document.getElementById("fTimer"));

	var loaderOut = document.getElementById("loader");
	loaderOut.style.display = "block";

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch("https://" + endPointApi + "/react/endpoints/ws-last-evaluation.php", {
		method: "POST",
		body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			document.getElementById("fTimer").classList.remove("tmActive");
			document.getElementById("fTimer").classList.add("tmInactive");

			newQuestion = FnConsumeData(results);
			ReactDOM.render("", document.getElementById("wpElement"));
			ReactDOM.render(newQuestion, document.getElementById("wpElement"));
			loaderOut.style.display = "none";
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default LastEvaluation;
