import React from "react";

import "../assets/css/name.css";

function CmName(data) {
	var name1 = "";
	var name2 = "";
	var name3 = "";

	if (data.aw !== null) {
		var nameAnw = data.aw.split("-");
		name1 = nameAnw[0];
		name2 = nameAnw[1];
		name3 = nameAnw[2];
	}

	return (
		<div className='gnt-name-section'>
			<div className='gnt-name-input'>
				<input
					type='text'
					id='name1'
					placeholder={data.st1}
					defaultValue={name1}
				/>
				<input
					type='text'
					id='name2'
					placeholder={data.st2}
					defaultValue={name2}
				/>
				<input
					type='text'
					id='name3'
					placeholder={data.st3}
					defaultValue={name3}
				/>
			</div>
		</div>
	);
}

export default CmName;
