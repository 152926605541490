import React, { Fragment } from "react";

import CmImage from "../components/cmImage";
import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmInputText from "../components/cmInputText";
import CmButton from "../components/cmButton";

function Welcome(data) {
	var vCmImage = CmImage(data);
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmInputText = CmInputText(data);
	var vCmButton = CmButton(data);

	var question = (
		<Fragment>
			{vCmImage}
			{vCmTitle}
			{vCmDescription}
			{vCmInputText}
			{vCmButton}
			<div id='errorMessage'></div>
		</Fragment>
	);
	return question;
}

export default Welcome;
