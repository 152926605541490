import React, { Fragment } from "react";
import Cookies from "universal-cookie";

import CmTitle from "../components/cmTitle";

import SignatureCanvas from "react-signature-canvas";
import fnSendImage from "../apis/apiSendImageSignature";
import FnNextQuestion from "../functions/fnNextQuestion";

import "../assets/css/signature.css";

function Signature(data) {
	var vCmTitle = CmTitle(data);
	const setCookies = new Cookies();

	const clearCanvas = () => {
		var canvas = document.querySelector(".sigCanvas");
		var context = canvas.getContext("2d");
		context.fillStyle = "#FFF";
		context.fillRect(0, 0, canvas.width, canvas.height);
	};

	const saveCanvas = () => {
		var canvas = document.querySelector(".sigCanvas");
		var sigB64 = canvas.toDataURL().replace("data:image/png;base64,", "");
		fnSendImage(data.tk, setCookies.get("session"), sigB64, "front", "sign");
		FnNextQuestion(data);
	};

	return (
		<Fragment>
			{vCmTitle}
			<div className='sigContent'>
				<div className='sigElement'>
					<SignatureCanvas
						penColor='black'
						canvasProps={{ width: 543, height: 187, className: "sigCanvas" }}
					/>
				</div>
				<div className='sigButtons'>
					<button onClick={clearCanvas}>Limpiar</button>
					<button onClick={saveCanvas}>Guardar</button>
				</div>
			</div>
		</Fragment>
	);
}

export default Signature;
