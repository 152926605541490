import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import fnConsumeData from "../functions/fnConsumeData";

class CmNavigator extends Component {
	onClickPrev = () => {
		const setCookies = new Cookies();
		var dataForm = new FormData();
		let endPointApi = process.env.REACT_APP_BACKEND_DEV;

		dataForm.append("token", this.props.data.tk);
		dataForm.append("session", setCookies.get("session"));
		dataForm.append("idQuestion", this.props.data.id);
		dataForm.append("idForm", this.props.data.if);
		dataForm.append("idLogicOrder", this.props.data.lc);
		dataForm.append("typeForm", this.props.data.tf);

		if (document.domain === "go.moyoai.com")
			endPointApi = process.env.REACT_APP_BACKEND_PROD;

		fetch(
			"https://" + endPointApi + "/react/endpoints/ws-search-prev-question.php",
			{
				method: "POST",
				body: dataForm
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then((results) => {
				var pvBtton = "";
				var nxBtton = "";

				if (
					results.pv === "true" &&
					(results.ty !== "ws")
				) {
					pvBtton = (
						<button
							onClick={this.onClickPrev}
							className='btn btn-nav'
							id='nvPrev'>
							{"<"}
						</button>
					);
				}

				if (results.nx === "true") {
					nxBtton = (
						<button
							onClick={this.onClickNext}
							className='btn btn-nav'
							id='nvNext'>
							{">"}
						</button>
					);
				}

				var bttnsNav = pvBtton + nxBtton;
				ReactDOM.render(bttnsNav, document.getElementById("vw-navigator"));
				ReactDOM.render("", document.getElementById("wpElement"));
				var newQuestion = fnConsumeData(results);
				ReactDOM.render(newQuestion, document.getElementById("wpElement"));
			})
			.catch(function (err) {
				console.error(err);
			});
	};

	onClickNext = () => {
		const setCookies = new Cookies();
		var dataForm = new FormData();
		let endPointApi = process.env.REACT_APP_BACKEND_DEV;

		dataForm.append("token", this.props.data.tk);
		dataForm.append("session", setCookies.get("session"));
		dataForm.append("idQuestion", this.props.data.id);
		dataForm.append("idForm", this.props.data.if);
		dataForm.append("idLogicOrder", this.props.data.lc);
		dataForm.append("typeForm", this.props.data.tf);

		if (document.domain === "go.moyoai.com")
			endPointApi = process.env.REACT_APP_BACKEND_PROD;

		fetch(
			"https://" + endPointApi + "/react/endpoints/ws-search-next-question.php",
			{
				method: "POST",
				body: dataForm
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then((results) => {
				var pvBtton = "";
				var nxBtton = "";

				if (results.pv === "true") {
					pvBtton = (
						<button
							onClick={this.onClickPrev}
							className='btn btn-nav'
							id='nvPrev'>
							{"<"}
						</button>
					);
				}

				if (results.nx === "true") {
					nxBtton = (
						<button
							onClick={this.onClickNext}
							className='btn btn-nav'
							id='nvNext'>
							{">"}
						</button>
					);
				}

				var bttnsNav = pvBtton + nxBtton;
				ReactDOM.render(bttnsNav, document.getElementById("vw-navigator"));

				ReactDOM.render("", document.getElementById("wpElement"));
				let nextQuestion = fnConsumeData(results);
				ReactDOM.render(nextQuestion, document.getElementById("wpElement"));
			})
			.catch(function (err) {
				console.error(err);
			});
	};

	render() {
		var pvBtton = "";
		var nxBtton = "";
		if (this.props.data.pv === "true")
			pvBtton = (
				<button
					onClick={this.onClickPrev}
					className='btn btn-nav'
					id='nvPrev'>
					{"<"}
				</button>
			);

		if (this.props.data.nx === "true")
			nxBtton = (
				<button
					onClick={this.onClickNext}
					className='btn btn-nav'
					id='nvNext'>
					{">"}
				</button>
			);

		return (
			<Fragment>
				{pvBtton}
				{nxBtton}
			</Fragment>
		);
	}
}

export default CmNavigator;
