import React from "react";

import "../assets/css/dropdown.css";

function CmDropDown(data) {
	var objectItems = Object.keys(data).map((key) => {
		if (key.substring(0, 2) === "op") {
			return (
				<option key={"e" + key} value={key}>
					{data[key]}
				</option>
			);
		} else {
			return null;
		}
	});

	return (
		<select id='gntSelect' defaultValue={data.aw}>
			<option value='op0'>Selecciona una opción</option>
			{objectItems}
		</select>
	);
}

export default CmDropDown;
