import React, { Fragment } from "react";

import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmImage from "../components/cmImage";
import CmButton from "../components/cmButton";

function Statement(data) {
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmImage = CmImage(data);
	var vCmButton = CmButton(data);

	var question = (
		<Fragment>
			{vCmTitle}
			{vCmDescription}
			{vCmImage}
			{vCmButton}
		</Fragment>
	);
	return question;
}

export default Statement;
