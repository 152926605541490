import Cookies from "universal-cookie";

import fnTimerForms from "../functions/fnTimerForms";

function fnSaveAnswerVanishing(data, answer, order) {
	var cokSession = null;
	const setCookies = new Cookies();
	if(setCookies.get("session"))
		cokSession = setCookies.get("session");

	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", data.tk);
	dataForm.append("session", cokSession);
	dataForm.append("idQuestion", data.id);
	dataForm.append("idForm", data.if);
	dataForm.append("answer", answer);
	dataForm.append("ansOrder", order);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch(
		"https://" + endPointApi + "/endpoints/ws-react-save-answers-vanishing.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			if(cokSession === null && results["is"] === 1){
				fnTimerForms(data);
				let currentDate = new Date();
				let minutesAdd = results["ts"]+60;

				if (minutesAdd !== 0) {
					currentDate.setMinutes(currentDate.getMinutes() + parseInt(minutesAdd));
				}

				const setCookies = new Cookies();
				setCookies.set("session", results["ss"], {
					path: "/",
					expires: currentDate,
					sameSite: "none",
					secure: true
				});
			}

			document.getElementById("loader").style.display = "none";
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default fnSaveAnswerVanishing;
