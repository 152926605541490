import Cookies from "universal-cookie";
import ReactDOM from "react-dom";

import FnQuestions from "../functions/fnQuestions";
import fnTimerForms from "../functions/fnTimerForms";

function ApiSaveAnswer(data, answer){
	var cokSession = null;
    const setCookies = new Cookies();
	if(setCookies.get("session"))
		cokSession = setCookies.get("session");

	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", data.tk);
	dataForm.append("session", cokSession);
	dataForm.append("idQuestion", data.id);
	dataForm.append("idForm", data.if);
	dataForm.append("answer", answer);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch(
		"https://" + endPointApi + "/endpoints/ws-react-save-answers.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			if(data.ty !== "ws" && data.ty !== "tk"){
				if(cokSession === null && results["is"] === 1){
					let currentDate = new Date();
					let minutesAdd = results["ts"];
					currentDate.setMinutes(currentDate.getMinutes() + parseInt(minutesAdd));

					const setCookies = new Cookies();
					setCookies.set("session", results["ss"], {
						path: "/",
						expires: currentDate,
						sameSite: "none",
						secure: true
					});

					fnTimerForms(data, results);
				}
			}

			// nextQuestion
			ReactDOM.render("", document.getElementById("wpElement"));
			FnQuestions(data, answer);
		})
		.catch(function (err) {
			console.error(err);
		});
}

export default ApiSaveAnswer;