import React from "react";
import ReactDOM from "react-dom";

import GeneralQuestion from "../apis/apiGeneralQuestion";
import ApiScoreQuestion from "../apis/apiScoreQuestion";
import "../assets/css/opinionScale.css";

class CmOpinionScale extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = { questionItem: null };
	}

	handleClick(value) {
		var itemOpscale = document.getElementsByClassName("opscaleItem");
		var e = document.getElementById("e" + value);
		for (var i = 0; i < itemOpscale.length; i++) {
			itemOpscale[i].classList.remove("active");
		}

		switch (this.props.data.tf) {
			case "score":
				e.classList.add("active");
				setTimeout(() => {
					let newQuestion = ApiScoreQuestion(this.props.data, value);
					ReactDOM.render(newQuestion, document.getElementById("wpElement"));
				}, 500);
				break;
			default:
				e.classList.add("active");
				setTimeout(() => {
					let newQuestion = GeneralQuestion(this.props.data, value);
					ReactDOM.render(newQuestion, document.getElementById("wpElement"));
				}, 500);
				break;
		}
	}

	render() {
		var elementsScale = 5;
		var showlb = "off";
		if (this.props.data.es) elementsScale = this.props.data.es;
		if (this.props.data.wl) showlb = this.props.data.wl;

		let objectItems = [];

		for (let i = 1; i <= elementsScale; i++) {
			objectItems.push(
				<li
					className='opscaleItem'
					key={"e" + i}
					id={"e" + i}
					onClick={(e) => this.handleClick(i)}>
					<span className='scaleCheck'></span> {i}
				</li>
			);
		}

		return (
			<div className='gnt-opscale-section'>
				<div className='gnt-opscale-col'>
					<ul className='gnt-forms-scale w5'>{objectItems}</ul>
				</div>
				<div className='gnt-opscale-col'>
					<div className='oslabel' data-flex-show={showlb}>
						<div className='oslabel-item d-flex items-left'>
							<p id='oslf'>{this.props.data.osin}</p>
						</div>
						<div className='oslabel-item d-flex items-center'>
							<p id='oscn'>{this.props.data.osmd}</p>
						</div>
						<div className='oslabel-item d-flex items-bottom'>
							<p id='osrt'>{this.props.data.osen}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CmOpinionScale;
