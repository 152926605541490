import React from "react";

import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmIcon from "../components/cmIcon";
import CmButtonCameraMask from "../components/cmButtonCameraMask";

import "../assets/css/photo.css";

function Selfie(data) {
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmIcon = CmIcon(data);

	var question = (
		<div className='vw-question'>
			<div className='vw-element' id={data.ty}>
				<div className='gnt-validate-section text-center' id='sf'>
					<div className='gnt-front-picture' id='fntPic'>
						{vCmTitle}
						{vCmDescription}
						{vCmIcon}
						{CmButtonCameraMask(data, "front")}
					</div>
				</div>
			</div>
		</div>
	);
	return question;
}

export default Selfie;
