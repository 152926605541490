import React, { Fragment } from "react";

import CmTitle from "../components/cmTitle";
import CmImage from "../components/cmImage";
import CmDescription from "../components/cmDescription";
import CmPictureChoice from "../components/cmPictureChoice";

function PictureChoice(data) {
	var vCmTitle = CmTitle(data);
	var vCmImage = CmImage(data);
	var vCmDescription = CmDescription(data);
	var vCmPictureChoice = <CmPictureChoice data={data} />;

	var question = (
		<Fragment>
			{vCmTitle}
			{vCmImage}
			{vCmDescription}
			{vCmPictureChoice}
		</Fragment>
	);
	return question;
}

export default PictureChoice;
