import React from "react";

import MultipleInputsElements from "../apis/apiMultipleInputs";

import "../assets/css/multipleInputs.css";

function CmMultipleInputs(data) {
	return (
		<div className='gnt-description-section'>
			<div className='gnt-mi-elements' id='miElements'>
				{MultipleInputsElements(data)}
			</div>
		</div>
	);
}

export default CmMultipleInputs;
