import React from "react";

import "../assets/css/input.css";

function CmTextArea(data) {
	var vPlaceHolder = "Escribe tu respuesta aquí...";
	var taValue = "";

	if(data.aw !== ""){
		taValue = data.aw;
	}

	return (
		<div className='gnt-textarea-section'>
			<textarea id='gntTextArea' placeholder={vPlaceHolder}>{taValue}</textarea>
		</div>
	);
}

export default CmTextArea;
