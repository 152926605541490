import React, {Component} from "react";
import ReactFlagsSelect from "react-flags-select";

class apiAreaCode extends Component {
	constructor(){
		super();
		this.state = {selected: "", countries: [""], customLabels: {MX: { primary: "", secondary: "", mxlength: 10}}, flAreaCode: "", lgPhoneNumber: 0}
	}

	componentDidMount(){
		let dataForm = new FormData();
		dataForm.append("token", this.props.token);

		let endPointApi = process.env.REACT_APP_BACKEND_DEV;
		if (document.domain === "go.moyoai.com")
			endPointApi = process.env.REACT_APP_BACKEND_PROD;

		
		fetch(
			"https://" + endPointApi + "/react/endpoints/api-area-code.php",
			{
				method: "POST",
				body: dataForm
			}
		)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			var idSelected = [];
			var idCountries = [];
			var jsoncustomLabels = [];

			var mpSelected = results.map((key) => {
				if(key.cat_default === 1){
					idSelected = key.cat_nom;
				}

				return idSelected;
			});
			if(idSelected.length === 0)
				this.setState({ selected: 0 });

			var actSelected = mpSelected.map((key) => {
				if(key !== null)
					this.setState({ selected: key });

				return null;
			});

			var countries = results.map((key) => {
				idCountries.push(key.cat_nom);
				return idCountries;
			});
			this.setState({ countries: countries[0] });

			var customLabels = results.map((key) => {
				let areaCodeAnsw = this.props.areaCode;
				if(key.cat_code === areaCodeAnsw.substr(1)){
					this.setState({ selected: key.cat_nom });
					this.setState({ lgPhoneNumber: key.cat_length });
				}
				jsoncustomLabels[key.cat_nom] = { primary: key.cat_country, secondary: "+"+key.cat_code, mxlength: key.cat_length};
				return jsoncustomLabels;
			});
			this.setState({ customLabels: customLabels[0] });


			if(this.state.customLabels[this.state.selected] !== undefined){
				this.setState({ flAreaCode: this.state.customLabels[this.state.selected].secondary });
			}
			return actSelected;
		})
		.catch(function (err) {
			console.error(err);
		});
	}

	render(){
		var phSelect = "Buscar por nombre de país";
		var phSelectCountry = "País";
		const onSelect = (code => {
			document.getElementById("gntInput").setAttribute("maxlength", this.state.customLabels[code].mxlength);
			document.getElementById("gntInput").value = "";
			this.setState({ flAreaCode: this.state.customLabels[code].secondary });
			this.setState({ selected: code });
			this.setState({ lgPhoneNumber: this.state.customLabels[code].mxlength});
		});

		if(document.getElementById("app").getAttribute("lang") === "en"){
			phSelect = "Search by country name";
			phSelectCountry = "Code";
		}

		var selectElement = <ReactFlagsSelect
			selected={this.state.selected}
			selectedSize={16}
			countries= {this.state.countries}
			customLabels={this.state.customLabels}
			onSelect={onSelect}
			showSelectedLabel={false}
			showSecondarySelectedLabel={true}
			searchable={true}
			searchPlaceholder={phSelect}
			placeholder={phSelectCountry}
			id="flags-select"
		/>

		return <div className="areaCode">
			{selectElement}
			{<input type="hidden" id="flags-select-areacode" value={this.state.flAreaCode} />}
			{<input type="hidden" id="length-phone-number" value={this.state.lgPhoneNumber} />}
		</div>
	}
	
}

export default apiAreaCode;