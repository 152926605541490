import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import fnConsumeData from "../functions/fnConsumeData";
import fnRestartServicesFQ from "../functions/fnRestartServicesFQ";

function ApiSearchLastQuestion(token) {
	const setCookies = new Cookies();
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", token);
	dataForm.append("session", setCookies.get("session"));

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch(
		"https://" + endPointApi + "/endpoints/ws-search-last-question.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			if (results["oq"] !== undefined && results["oq"] > 0)
				fnRestartServicesFQ(token);

			var newQuestion = fnConsumeData(results);
			ReactDOM.render(newQuestion, document.getElementById("wpElement"));
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default ApiSearchLastQuestion;
