import React, { Fragment } from "react";

import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmName from "../components/cmName";
import CmButton from "../components/cmButton";

function Name(data) {
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmName = CmName(data);
	var vCmButton = CmButton(data);

	var question = (
		<Fragment>
			{vCmTitle}
			{vCmDescription}
			{vCmName}
			{vCmButton}
			<div id='errorMessage'></div>
		</Fragment>
	);
	return question;
}

export default Name;
