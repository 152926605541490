import React, { Component } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import TakePhotosMask from "../functions/fnTakePhotosMask";
import "../assets/css/cameraSelfie.css";

class SelfieCamMask extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = { dataUri: null };
		this.onTakePhotoAnimationDone = this.onTakePhotoAnimationDone.bind(this);
	}

	onTakePhotoAnimationDone(dataUri) {
		TakePhotosMask(dataUri, this.props.data, this.props.typeCam);
	}

	render() {
		return (
			<div className='vw-container'>
				<div className='vw-question'>
					<div className='vw-element cam-selfie'>
						<div className='camMask' id='camMask'>
							<div className='camSections'>
								<div className='camTitle'>
									<h2>{this.props.data.ti}</h2>
								</div>
								<div className='camPhoto clSelfie'>
									<div className='camMaskItem'></div>
									<Camera
										id='camImage'
										idealFacingMode={FACING_MODES.USER}
										isMaxResolution={false}
										idealResolution = {{width: 640, height: 480}}
										imageType={IMAGE_TYPES.JPG}
										imageCompression={0.97}
										isImageMirror={true}
										isSilentMode={false}
										isDisplayStartCameraError={true}
										isFullscreen={true}
										sizeFactor={1}
										onTakePhotoAnimationDone={this.onTakePhotoAnimationDone}
									/>
								</div>
								<div className='camInfo'>
									<p>Tómese la selfie en un lugar bien iluminado, sin gafas, ni pelo en la cara.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SelfieCamMask;
