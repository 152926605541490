import React from "react";
import ReactDOM from "react-dom";

import IcoCheck from "../assets/icons/icCheck";
import IcoIdImage from "../assets/icons/icIdImage";

import "../assets/css/documentChoice.css";

class CmDocumentsChoice extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(id) {
		var cards = document.getElementsByClassName("card-check");
		var e = document.getElementById("ck" + id);

		for (var i = 0; i < cards.length; i++) {
			cards[i].setAttribute("dataactive", "off");
			cards[i].style.display = "none";
		}
		e.setAttribute("dataactive", "on");
		e.style.display = "block";
	}

	render() {
		var cardID = 0;
		var dataForm = new FormData();
		let endPointApi;
		var token = this.props.data.tk;

		dataForm.append("token", token);

		if (document.domain === "go.moyoai.com") {
			endPointApi = process.env.REACT_APP_BACKEND_PROD;
		} else {
			endPointApi = process.env.REACT_APP_BACKEND_DEV;
		}

		fetch(
			"https://" + endPointApi + "/endpoints/ws-react-search-documents.php",
			{
				method: "POST",
				body: dataForm
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then((results) => {
				var optionItems = results.map((optns) => {
					cardID++;

					return (
						<div
							className='card'
							onClick={(e) => this.handleClick(optns.cat_id)}
							key={cardID}>
							<div className='card-image'>{IcoIdImage(optns.cat_abbrv)}</div>
							<div
								className='card-check'
								dataactive='off'
								datatype={optns.cat_abbrv}
								id={"ck" + optns.cat_id}>
								<div className='card-check-mask'></div>
								{IcoCheck()}
							</div>
							<div className='card-body'>
								<h5 className='card-title text-center'>{optns.cat_name}</h5>
							</div>
						</div>
					);
				});

				ReactDOM.render(optionItems, document.getElementById("docchs"));
			})
			.catch(function (err) {
				console.error(err);
			});
		return null;
	}
}

export default CmDocumentsChoice;
