import ReactDOM from "react-dom";
import Cookies from "universal-cookie";

import fnIdentityResources from "../functions/fnIdentityResources";
import fnConsumeData from "../functions/fnConsumeData";

function fnNextForm(data, session) {
	const setCookies = new Cookies();

	window.clearTimeout(window.popupTimer);

	var dataForm = new FormData();
	let endPointApi;

	dataForm.append("token", data.tk);
	dataForm.append("idForm", data.if);
	dataForm.append("session", session);

	var loaderOut = document.getElementById("loader");
	loaderOut.style.display = "block";

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch("https://" + endPointApi + "/react/endpoints/ws-next-form.php", {
		method: "POST",
		body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			if (data.tf === "identity")
				fnIdentityResources(data, setCookies.get("session"));

			loaderOut.style.display = "none";
			var newQuestion = fnConsumeData(results);
			ReactDOM.render("", document.getElementById("wpElement"));
			ReactDOM.render(newQuestion, document.getElementById("wpElement"));
		})
		.catch(function (err) {
			console.error(err);
		});
}

export default fnNextForm;
