import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { format } from 'date-fns';

import "../assets/css/datepicker.css";

export default class CmDatePicker extends Component {
	constructor() {
		super();
		this.state = {
			time: new Date(),
			isOpen: false,
			dateAge: null
		};
	}

	handleClick = () => {
		if (this.props.data.aw !== "null" && this.props.data.aw !== null) {
			this.setState({ time: new Date(document.getElementById("dateInput").innerHTML) });
		}

		this.setState({ isOpen: true });
	}

	handleCancel = () => {
		this.setState({ isOpen: false });
	}

	handleSelect = (time) => {
		const monthMap = {
			'1': 'Ene',
			'2': 'Feb',
			'3': 'Mar',
			'4': 'Abr',
			'5': 'May',
			'6': 'Jun',
			'7': 'Jul',
			'8': 'Ago',
			'9': 'Sep',
			'10': 'Oct',
			'11': 'Nov',
			'12': 'Dic',
		};

		this.setState({ time, isOpen: false });
		let dateCurrent = format(new Date(time), 'dd-MM-yyyy');

		let arDate = dateCurrent.split("-");
		let dateYear = arDate[2];
		let dateMonth = arDate[1];
		let dateDay = arDate[0];
		let dateFormat = dateDay + "-" + monthMap[parseInt(dateMonth)] + "-" + dateYear;

		document.getElementById("dateInput").innerHTML = dateFormat;
		document.getElementById("dateInput").setAttribute("refDate", dateCurrent);

		if (this.props.type === "birthday") {
			let today = new Date()
			let brithday = new Date(time);
			let age = today.getFullYear() - brithday.getFullYear()
			let diferenciaMeses = today.getMonth() - brithday.getMonth()
			if (
				diferenciaMeses < 0 ||
				(diferenciaMeses === 0 && today.getDate() < brithday.getDate())
			) {
				age--
			}
			this.setState({ "dateAge": <div className="mai-birthday-age"><p>Edad: {age} años</p></div> });
		}
	}

	componentDidMount() {
		if (this.props.type === "birthday") {
			if (this.props.data.aw !== "null" && this.props.data.aw !== null) {
				let today = new Date()
				let brithday = new Date(this.props.data.aw);
				let age = today.getFullYear() - brithday.getFullYear()
				let diferenciaMeses = today.getMonth() - brithday.getMonth()
				if (
					diferenciaMeses < 0 ||
					(diferenciaMeses === 0 && today.getDate() < brithday.getDate())
				) {
					age--
				}

				let awData = this.props.data.aw.split("-");
				let dateInputAttr = awData[2] + "-" + awData[1] + "-" + awData[0];
				document.getElementById("dateInput").setAttribute("refDate", dateInputAttr);
				this.setState({ "dateAge": <div className="mai-birthday-age"><p>Edad: {age} años</p></div> });
			}
		}
	}

	render() {
		var d = new Date();
		var minYear = d.getFullYear() - this.props.data.mx;
		var maxYear = d.getFullYear() + 5;
		var month = d.getMonth();
		var day = d.getDate();
		var dateInputText = "";
		var minDate = new Date(minYear, month, day);
		var maxDate = new Date(maxYear, month, day);

		const monthMap = {
			'1': 'Ene',
			'2': 'Feb',
			'3': 'Mar',
			'4': 'Abr',
			'5': 'May',
			'6': 'Jun',
			'7': 'Jul',
			'8': 'Ago',
			'9': 'Sep',
			'10': 'Oct',
			'11': 'Nov',
			'12': 'Dic',
		};

		const dateConfig = {
			'year': {
				format: 'YYYY',
				caption: 'Year',
				step: 1,
			},
			'month': {
				format: value => monthMap[value.getMonth() + 1],
				caption: 'Mon',
				step: 1,
			},
			'date': {
				format: 'DD',
				caption: 'Day',
				step: 1,
			},
		};

		if (this.props.data.aw === "null" || this.props.data.aw === null) {
			dateInputText = this.props.data.ph;
		} else {
			let awData = this.props.data.aw.split("-");
			dateInputText = awData[2] + "-" + monthMap[parseInt(awData[1])] + "-" + awData[0];
		}

		if (this.props.type === "birthday") {
			const d = new Date();
			let year = d.getFullYear() - this.props.data.mn;
			let month = d.getMonth();
			let day = d.getDate();
			maxDate = new Date(year, month, day);
		}

		return (
			<div className='cmDatePicker'>
				<button id="dateInput" onClick={this.handleClick}>{dateInputText}</button>
				<DatePicker
					theme="ios"
					dateConfig={dateConfig}
					confirmText="OK"
					cancelText="Cancel"
					min={minDate}
					max={maxDate}
					headerFormat="DD-MM-YYYY"
					value={this.state.time}
					isOpen={this.state.isOpen}
					onSelect={this.handleSelect}
					onCancel={this.handleCancel} />

				{this.state.dateAge}
			</div>
		);
	}
}