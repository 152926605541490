import React, { Fragment } from "react";
import Cookies from "universal-cookie";

import GoogleMapReact from 'google-map-react';
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";

import fnSaveGeolocation from "../apis/apiSaveGeolocation";
import ApiSaveGeolocationMap from "../apis/apiSaveGeolocationMap";

import CmTitle from "./cmTitle";
import IcoMarker from "../assets/icons/icMarker";
import "../assets/css/mapGoogle.css";

export default class MapGoogle extends React.Component {
    constructor(props) {
        super(props);
        if(this.props.data.aw !== null){
            var awData = JSON.parse(this.props.data.aw);
            this.state = {
                mapCenter: {
                    lat: parseFloat(awData.latitude),
                    lng: parseFloat(awData.longitude)
                },
                nmInt: awData.numInt,
                nmExt: awData.numExt,
                address: ''
            }
        }else{
            this.state = {
                mapCenter: {
                    lat: this.props.latitude,
                    lng: this.props.longitude
                },
                nmInt: '',
                nmExt: '',
                address: ''
            };
        }
    }

    componentDidMount() {
        const setCookies = new Cookies();
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV);
        Geocode.fromLatLng(this.state.mapCenter.lat, this.state.mapCenter.lng).then(
            (response) => {
                const nameAddress = response.results[0].formatted_address;
                this.setState({ address: nameAddress });
            },
            (error) => {
                console.error(error);
            }
        );

        fnSaveGeolocation(this.props.data, setCookies.get("session"), "geolocation Ok", this.state.mapCenter.lat, this.state.mapCenter.lng);
    }

    handleChange = (address) => {
        this.setState({ address });
    };

    handleSelect = (address) => {
        const nameAddress = address.formatted_address;
        Geocode.fromAddress(nameAddress).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({ address: nameAddress });
                this.setState({
                    mapCenter: {
                        lat: lat,
                        lng: lng
                    }
                })
            },
            (error) => {
                console.error(error);
            }
        );
    };

    handleSave = () => {
        const setCookies = new Cookies();
        var addressTxt = document.getElementById("chAddressText").innerText;
        var numExt = document.getElementById("numExt").value;
        var numInt = document.getElementById("numInt").value;
        document.getElementById("loader").style.display = "block";

        ApiSaveGeolocationMap(
            this.props.data,
            setCookies.get("session"),
            addressTxt,
            this.state.mapCenter.lat,
            this.state.mapCenter.lng,
            numExt,
            numInt
        )
    }

    fnChangeAddress = () => {
        let textAddress = document.getElementById("cntGoogleAddress").querySelector("input").value;
        let textTitleAddress = document.getElementById("chAddressText");
        textTitleAddress.innerHTML = textAddress;
        let chAddress = document.getElementById("chAddress");
        chAddress.style.display = "flex";
    };

    fnChangeAddressCont = () => {
        let chAddress = document.getElementById("chAddress");
        chAddress.style.display = "none";
    };

    render() {
        var vCmTitle = CmTitle(this.props.data);
        const defaultProps = {
            center: {
                lat: this.props.latitude,
                lng: this.props.longitude
            },
            zoom: 17
        };

        const onMarkerDragEnd = (evt) => {
            let lat = evt.center.lat();
            let lng = evt.center.lng();

            this.setState({
                mapCenter: {
                    lat: lat,
                    lng: lng
                }
            })

            Geocode.fromLatLng(
                lat,
                lng
            ).then(
                (response) => {
                    const nameAddress = response.results[0].formatted_address;
                    document.querySelector(".pac-target-input").value = nameAddress;
                    let textTitleAddress = document.getElementById("chAddressText");
                    textTitleAddress.innerHTML = nameAddress;
                },
                (error) => {
                    console.error(error);
                }
            );
        }

        return (
            <Fragment>
                <div className='changeAddress' id='chAddress'>
                    <div className='changeAddressContent'>
                        <div className="btnClose" onClick={this.fnChangeAddressCont}>X</div>
                        <h2 id='chAddressText'>{this.state.address}</h2>
                        <div className='chaInput'>
                            <p>No. Exterior</p>
                            <input
                                name='numExt'
                                id='numExt'
                                placeholder='Ingrese el número exterior correcto'
                                defaultValue={this.state.nmExt}
                            />
                        </div>
                        <div className='chaInput'>
                            <p>No. Interior</p>
                            <input
                                name='numInt'
                                id='numInt'
                                placeholder='Ingrese el número interior correcto'
                                defaultValue={this.state.nmInt}
                            />
                        </div>
                        <div className="btnButtons">
                            <button
                                className='btnSuccess'
                                onClick={this.fnChangeAddressCont}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mai-geolocation">
                    {vCmTitle}
                    <div className="mai-description">
                        <p>Mueva el pin a la ubicación correcta</p>
                    </div>

                    <div className='gnt-mapGoogle-address text-center'>
                        <div className='gnt-mapGoogle-address-control' id='cntGoogleAddress'>
                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV}
                                style={{ width: "70%" }}
                                onPlaceSelected={(place) => {
                                    this.handleSelect(place);
                                }}
                                options={{
                                    types: ['address'],
                                }}
                                defaultValue={this.state.address}
                            />
                            <button className='btnSuccess' onClick={this.fnChangeAddress}>Detalle</button>
                        </div>
                    </div>

                    <div style={{ height: '300px', width: '100%', margin: '1.5rem 0px 1rem', position: 'relative' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV, libraries: ['places'] }}
                            center={this.state.mapCenter}
                            defaultZoom={defaultProps.zoom}
                            onDragEnd={(e) => onMarkerDragEnd(e)}
                        ></GoogleMapReact>
                        <i className='iconGeo'>{IcoMarker()}</i>
                    </div>
                    <div className="btnButtons text-center">
                        <button className="btnSuccess"
                        onClick={this.handleSave}>
                            Continuar
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}