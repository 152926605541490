import React, { Component, Fragment } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";

import "react-html5-camera-photo/build/css/index.css";

import TakePhotosMask from "../functions/fnTakePhotosMask";
import "../assets/css/cameraMask.css";

class IDFrontCam extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = { dataUriPrev: null };
		this.state = { crop: { width: 640, height: 480 } };
	}
	
	handleTakePhotoAnimationDone (dataUri, data, typeCam) {
		TakePhotosMask(dataUri, data, typeCam);
	}

	render() {
		var idealResolData = {width: 640, height: 480};
		var clsIDDocument = "clIDDocument";
		if(this.props.data.idDocument === "passport"){
			idealResolData = {width: 640, height: 640};
			clsIDDocument = "clPassport";
		}

		var docSide = this.props.data.sdb;
		if (this.props.typeCam === "front") docSide = this.props.data.sd;

		return (
			<Fragment>
				<div className='camMask' id='camMask'>
					<div className='camSections'>
						<div className='camTitle'>
							<h2>{this.props.data.ti}</h2>
							<p>{docSide}</p>
						</div>
						<div className={'camPhoto '+clsIDDocument}>
							<div className='camMaskItem'></div>
							<Camera
								id='camImage'
								idealFacingMode={FACING_MODES.ENVIRONMENT}
								isMaxResolution={false}
								idealResolution = {idealResolData}
								imageType={IMAGE_TYPES.JPG}
								imageCompression={0.97}
								isImageMirror={false}
								isSilentMode={false}
								isDisplayStartCameraError={true}
								isFullscreen={true}
								sizeFactor={1}
								onTakePhotoAnimationDone = { (dataUri) => { this.handleTakePhotoAnimationDone(dataUri, this.props.data, this.props.typeCam); } } 
							/>
						</div>
						<div className='camInfo'>
							<ul>
								<li>Verifica que el documento este dentro del margen</li>
								<li>Que haya suficiente luz</li>
							</ul>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default IDFrontCam;
