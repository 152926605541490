function ApiSendImage(token, session, tydocument, tyelement) {
	var isBase64 = require('is-base64');
	var showPicture = document.querySelector("#show-picture");
	var canvas = document.createElement("canvas"),
		ctx = canvas.getContext("2d");

	canvas.height = showPicture.naturalHeight;
	canvas.width = showPicture.naturalWidth;
	ctx.drawImage(showPicture, 0, 0);

	var imgBase64 = canvas
		.toDataURL("image/jpg")
		.replace(/^data:image.+;base64,/, "");

	if(isBase64(imgBase64)){
		var formData = new FormData();
		let endPointApi;
	
		formData.append("token", token);
		formData.append("session", session);
		formData.append("tydocument", tydocument);
		formData.append("tyelement", tyelement);
		formData.append("imageRSA", imgBase64);
	
		if (document.domain === "go.moyoai.com") {
			endPointApi = process.env.REACT_APP_BACKEND_PROD;
		} else {
			endPointApi = process.env.REACT_APP_BACKEND_DEV;
		}
	
		fetch(
			"https://" + endPointApi + "/wservices/react/ws-react-upload-photos.php",
			{
				method: "POST",
				body: formData
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then((results) => {
				return;
			});
	}else{
		alert("La fotografía no es valida, intente sacar otra");
	}

}

export default ApiSendImage;
