import React from "react";
import ReactDOM from "react-dom";

import SelfieCamMask from "../components/cmSelfieCamMask";
import IDFrontCam from "../components/cmIDFrontCam";

function CmButtonCameraMask(data, typeCam) {
	var textButton = "";
	var elCamera = "";

	function takephoto() {
		switch (data.ty) {
			case "sl":
				elCamera = <SelfieCamMask data={data} typeCam={typeCam} />;
				break;
			case "is":
				elCamera = <IDFrontCam data={data} typeCam={typeCam} />;
				break;
			default:
				break;
		}

		ReactDOM.render(elCamera, document.getElementById("wpElement"));
	}

	var langID = document.getElementById("app");
	switch (langID.getAttribute("lang")) {
		case "en":
			textButton = "Take photo";
			break;
		default:
			textButton = "Tomar foto";
			break;
	}

	return (
		<div className='cam btnButtons'>
			<label className={"btnSuccess"} onClick={takephoto}>
				{textButton}
			</label>
		</div>
	);
}

export default CmButtonCameraMask;
