import React, { Fragment } from "react";

import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmLegal from "../components/cmLegal";

function Legal(data) {
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmLegal = <CmLegal data={data} />;

	var question = (
		<Fragment>
			{vCmTitle}
			{vCmDescription}
			{vCmLegal}
			<div id='errorMessage'></div>
		</Fragment>
	);
	return question;
}

export default Legal;
