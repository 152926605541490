import React, { Fragment } from "react";
import Cookies from "universal-cookie";
import fnIdentityResources from "../functions/fnIdentityResources";
import CmImage from "../components/cmImage";
import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmButton from "../components/cmButton";

function ThankYou(data) {
	const setCookies = new Cookies();

	if (data.tf === "identity")
		fnIdentityResources(data, setCookies.get("session"));
	
	setTimeout(() => {
		setCookies.remove("session", { path: "/" });

		if (data.rd && data.rd !== "") window.location = data.rd;
			else window.location.reload();
	}, 10000);

	var vCmImage = CmImage(data);
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmButton = CmButton(data);
	var question = (
		<Fragment>
			{vCmImage}
			{vCmTitle}
			{vCmDescription}
			{vCmButton}
		</Fragment>
	);

	return question;
}

export default ThankYou;
