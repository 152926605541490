function SendPhotos(token, session, image, type) {
	var formData = new FormData();
	let endPointApi;

	formData.append("token", token);
	formData.append("session", session);
	formData.append("image", image);
	formData.append("type", type);

	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch(
		"https://" + endPointApi + "/endpoints/ws-react-upload-photo-timer.php",
		{
			method: "POST",
			body: formData,
		}
	).then(function (response) {
		return response.json();
	});
}

export default SendPhotos;
