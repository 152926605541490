import React from "react";

import "../assets/css/images.css";

import IconFormSearch from "../apis/apiIconFormSearch";

function CmIcon(data, side) {
	var showIM = "on";
	var alignIM = " text-center";
	if (data.ima) alignIM = " " + data.ima;

	return (
		<div
			className={"imgIcon" + alignIM}
			data-show={showIM}
			id='idImage'>
			{IconFormSearch(data, side)}
		</div>
	);
}

export default CmIcon;
