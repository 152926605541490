import React from "react";

import "../assets/css/documentside.css";

function CmDocumentSideBack(data) {
	var showSD = "off";
	var idP = 0;
	var textDS = "";
	var alignSD = " text-left";
	if (data.sdb && data.sdb !== "") {
		showSD = "on";
		alignSD = " " + data.sdba;
		textDS = data.sdb.split("\n").map((i) => {
			idP++;
			return <p key={idP}>{i}</p>;
		});
	}

	return (
		<div className={"gnt-documentside-section" + alignSD} data-show={showSD}>
			<div className='gnt-documentside-text'>{textDS}</div>
		</div>
	);
}

export default CmDocumentSideBack;
