import FnQuestions from "../functions/fnQuestions";

function ApiSaveGeolocationMap(
	data,
	session,
	address,
	lat,
	lng,
	numExt,
	numInt
) {
	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", data.tk);
	dataForm.append("session", session);
	dataForm.append("idQuestion", data.id);
	dataForm.append("idForm", data.if);
	dataForm.append("lat", lat);
	dataForm.append("lon", lng);
	dataForm.append("numExt", numExt);
	dataForm.append("numInt", numInt);
	dataForm.append("address", address);

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch(
		"https://" + endPointApi + "/react/endpoints/ws-save-geolocation-maps.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			setTimeout(() => {
				FnQuestions(data);
			}, 1000);
			return results;
		})
		.catch(function (err) {
			console.error(err);
		});
	return null;
}

export default ApiSaveGeolocationMap;
