import React from "react";
import ReactDOM from "react-dom";

import ApiSaveAnswerVanishing from "../apis/apiSaveAnswersVanishing";
import ApiSaveAnswer from "../apis/apiSaveAnswers";
import FnQuestions from "../functions/fnQuestions";

import CmButton from "../components/cmButton";
import IcoCheck from "../assets/icons/icCheck";
import "../assets/css/multipleChoice.css";

class CmMultipleChoice extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = { questionItem: null };
	}

	componentDidMount(){
		if(this.props.data.aw && this.props.data.aw !== "" && this.props.data.aw !== null){
			let awData = this.props.data.aw.split(";");
			if(awData[0] === "oth"){
				var mopoth = document.getElementById("mopoth").children;
				mopoth[2].style.display = "block";
				document.getElementById("othChoice").style.display = "block";
				document.getElementById("othValue").value = awData[1];
			}else{
				document.getElementById("ck" + this.props.data.aw).style.display = "block"; 
			}
		}
	}

	handleClick(key, value) {
		var chkElement = document.getElementById("ck" + key);
		var chkElementAll = document.querySelectorAll(".mcCk");
		var othElement = document.getElementById("othChoice");

		chkElementAll.forEach(element => {
			element.style.display = "none";
		});

		if(value === "Otro" || value === "OTRO"){
			// Mostrar la opción otro si el multiple choice no es tipo vanishing
			chkElement.style.display = "block";
			if(this.props.data.cf !== 1){
				othElement.style.display = "block";
			}
		}else{
			chkElement.style.display = "block";
			setTimeout(() => {
				chkElement.style.display = "none";
				setTimeout(() => {
					var mcObj = document.getElementById("mcObj");
					var cntLi = mcObj.getElementsByTagName("li").length;
					var ansOrder = 0;
					switch (cntLi) {
						case 4:
							ansOrder = 1;
							break;
						case 3:
							ansOrder = 2;
							break;
						case 2:
							ansOrder = 3;
							break;
						default:
							ansOrder = 4;
							break;
					}

					chkElement.style.display = "block";

					// Guardar resultado por tipo de formulario y tipo de elemento
					if(this.props.data.tf === "score"){
						switch(this.props.data.cf){
							case 1: // Guarda las opciones de vanishing
								var cntAnswer = mcObj.getElementsByTagName("li");

								// Guarda las primeras dos opciones
								ApiSaveAnswerVanishing(this.props.data, key, ansOrder);
								
								// Guarda el último resultado cuando quedan dos opciones
								if(cntAnswer.length === 2){
									document.getElementById("loader").style.display = "block";

									var answ = cntAnswer[0].id;
									if(cntAnswer[1].id !== key){
										answ = cntAnswer[1].id;
									}
									ApiSaveAnswerVanishing(this.props.data, answ, 4);
								}

								setTimeout(() => {
									if(cntAnswer.length === 2){ // Busca la siguiente pregunta si solo quedan dos opciones
										ReactDOM.render("", document.getElementById("wpElement"));
										FnQuestions(this.props.data);
									}else{ // Elimina la primer y segunda opción de la lista multiple
										let node = document.getElementById(key);
										node.parentNode.removeChild(node);
									}
								}, 600);
								break;
							default: // Guarda las opciones normales
								document.getElementById("loader").style.display = "block";
								ApiSaveAnswer(this.props.data, key);
								break;
						}
					}else{ // Guarda las opciones normales para los formularios de onboarding e identity
						document.getElementById("loader").style.display = "block";
						ApiSaveAnswer(this.props.data, key);
					}
				}, 150);
			}, 150);

			// Oculta e inicializa la opción de otro, si el elemento no es vanishing
			if(this.props.data.cf !== 1 && othElement){
				if(!!( othElement.offsetWidth || othElement.offsetHeight || othElement.getClientRects().length )){
					othElement.style.display = "none";
					document.getElementById("othValue").value = "";
				}
			}
		}
	}

	render() {
		var vCmButton = CmButton(this.props.data);
		var cntElement = 0;
		var otherOption = "";
		var othSection = "";
		var objectItems = Object.keys(this.props.data).map((key) => {
			if (key.substring(0, 2) === "op") {
				cntElement++;

				othSection = key;

				if (this.props.data.cf !== 1 && this.props.data[key] === "Otro") {
					othSection = "oth";
					otherOption = (
						<div className='gnt-choice-other' id='othChoice'>
							<input
								id='othValue'
								type='text'
								name='other'
								placeholder='Ingresa otra opción'
							/>
							{vCmButton}
							<div id='errorMessage'></div>
						</div>
					);
				}

				return (
					<li
						key={"e" + cntElement}
						id={key}
						onClick={(e) => this.handleClick(key, this.props.data[key])}>
						<div className='mcOption' id={"mop"+othSection}>
							<span className='mcId'>{cntElement}</span>
							<span className='mcTx'>{this.props.data[key]}</span>
							<span className='mcCk' id={"ck" + key}>
								{IcoCheck()}
							</span>
						</div>
						{otherOption}
					</li>
				);
			} else {
				return null;
			}
		});

		return (
			<div className="multiplechoice" data-align='horizontal'>
				<ul id='mcObj'>{objectItems}</ul>
			</div>
		);
	}
}

export default CmMultipleChoice;
