import React from "react";

import CmTitle from "../components/cmTitle";
import CmDescription from "../components/cmDescription";
import CmDocumentSideBack from "../components/cmDocumentSideBack";
import CmIcon from "../components/cmIcon";
import CmButtonCamera from "../components/cmButtonCamera";
import CmButton from "../components/cmButton";
import RetryPhoto from "../functions/fnRetryPhoto";

import "../assets/css/photo.css";

function IdentityDocumentBack(data) {
	var vCmTitle = CmTitle(data);
	var vCmDescription = CmDescription(data);
	var vCmButton = CmButton(data);
	var vCmDocumentSideBack = CmDocumentSideBack(data);
	var descrText = "";
	var buttonRText = "";
	var langID = document.getElementById("app");
	switch (langID.getAttribute("lang")) {
		case "en":
			descrText = "Check that the image is clear";
			buttonRText = "Retry";
			break;
		default:
			descrText = "Verifica que la imagen sea clara";
			buttonRText = "Reintentar";
			break;
	}

	var question = (
		<div className='vw-question'>
			<div className='vw-element' id={data.ty} data-side='front'>
				<div className='gnt-validate-section text-center' id='idFront'>
					<div className='gnt-front-picture' id='fntPic'>
						{vCmTitle}
						{vCmDocumentSideBack}
						{CmIcon(data, "back")}
						{vCmDescription}
						{CmButtonCamera(data)}
					</div>
					<div className='gnt-front-preview' id='fntPicPrev'>
						{vCmTitle}
						{vCmDocumentSideBack}

						<div className='gnt-front-preview-image'>
							<img src='' alt='' id='show-picture' />
						</div>

						<p>{descrText}</p>

						<div className='gnt-buttons-container text-center'>
							<div className='gnt-button-section'>
								<button
									className='btn btn-info'
									id={data.id}
									onClick={RetryPhoto}>
									{buttonRText}
								</button>
							</div>

							{vCmButton}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	return question;
}

export default IdentityDocumentBack;
