import React from "react";
import ReactDOM from "react-dom";

function MultipleInputsElements(data) {
	var formData = new FormData();
	let endPointApi;

	formData.append("token", data.tk);
	formData.append("idQuestion", data.id);
	if (document.domain === "go.moyoai.com") {
		endPointApi = process.env.REACT_APP_BACKEND_PROD;
	} else {
		endPointApi = process.env.REACT_APP_BACKEND_DEV;
	}

	fetch(
		"https://" +
			endPointApi +
			"/react/endpoints/ws-multiple-inputs-elements.php",
		{
			method: "POST",
			body: formData
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			var readOnlyInp = false;
			var idInput = "";
			var classInput = "mi-inp-vl";
			var cntElement = 0;
			var miElements = results.map((key) => {
				cntElement++;

				const handleBlur = () => {
					if (key["mi_type"] === "vl") {
						var valEl = 0;
						var valTotal = 0;
						var cntInpts =
							document.getElementsByClassName("mi-element-vl").length;
						for (var i = 1; i <= cntInpts; i++) {
							valEl = document.getElementById("vl" + i).value;
							if (valEl === "") valTotal = 0 + parseInt(valTotal);
							else valTotal = parseInt(valEl) + parseInt(valTotal);
						}
						document.getElementById("totEltns").value = valTotal;
					}
				};

				if (key["mi_type"] === "vl") idInput = key["mi_type"] + cntElement;
				else {
					readOnlyInp = true;
					idInput = "totEltns";
					classInput = "mi-inp-vl mi-inp-tl";
				}

				return (
					<div className={"mi-element-" + key["mi_type"]} key={cntElement}>
						<div className='mi-element-inp'>
							<input
								className={classInput}
								readOnly={readOnlyInp}
								type='text'
								defaultValue={key["mi_placeholder"]}
								maxLength={key["mi_maxlength"]}
								onBlur={handleBlur}
								id={idInput}
							/>
						</div>
						<div className='mi-element-txt'>
							<p>
								{key["mi_title"]} <span>{key["mi_tip"]}</span>
							</p>
						</div>
					</div>
				);
			});

			ReactDOM.render(miElements, document.getElementById("miElements"));
		})
		.catch(function (err) {
			console.error(err);
		});
}

export default MultipleInputsElements;
