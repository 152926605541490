import ReactDOM from "react-dom";

import ApiSaveAnswer from "../apis/apiSaveAnswers";
import FnAnswer from "./fnAnswer";
import FnQuestions from "./fnQuestions";

function FnNextQuestion(data) {
    var ans = FnAnswer(data);
    if (ans["errorMessage"] === "" && (ans["answer"] !== "" && ans["answer"] !== undefined && ans["answer"] !== null && ans["answer"] !== "op0")) {
        ReactDOM.render("", document.getElementById("wpElement"));
        document.getElementById("loader").style.display = "block";

        if(data.ty !== "ws" && data.ty !== "tk"){
            ApiSaveAnswer(data, ans["answer"]);
        }else{
			FnQuestions(data);
        }
    } else {
        document.getElementById("errorMessage").style.display = "block";
    }
}

export default FnNextQuestion;